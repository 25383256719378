@import "css.less";

/**
 * Maintenance mode
 */
body.in-maintenance{
  background-color:#000;
  color:#fff;
  h1{
    display: none;
  }
  .main{
     text-align:center;
     width:100%;
  }
  .text{
    font-family:Arial,Helvetica,sans-serif;
    text-align:center;
    margin-top:20px;
  }
  a {
    color: #cfa22b;
  }
}
