/*---------------------------------------------------
    LESS Elements 0.9
  ---------------------------------------------------
    A set of useful LESS mixins
    More info at: http://lesselements.com
  ---------------------------------------------------*/
/*spinner CSS */
@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
@keyframes chasingBallBounce {
  50% {
    transform: scale(0);
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'open_sansregular';
  src: url('../fonts/OpenSans-Regular-webfont.eot');
  src: url('../fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Regular-webfont.woff') format('woff'), url('../fonts/OpenSans-Regular-webfont.ttf') format('truetype'), url('../fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'open_sanslight';
  src: url('../fonts/OpenSans-Light-webfont.eot');
  src: url('../fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans-Light-webfont.woff') format('woff'), url('../fonts/OpenSans-Light-webfont.ttf') format('truetype'), url('../fonts/OpenSans-Light-webfont.svg#open_sanslight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Aller-Light';
  src: url('../fonts/Aller-Light.ttf.woff') format('woff'), url('../fonts/Aller-Light.ttf.svg#Aller-Light') format('svg'), url('../fonts/Aller-Light.ttf.eot'), url('../fonts/Aller-Light.ttf.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Aller-Light-Italic';
  src: url('../fonts/Aller-Light-Italic.ttf.woff') format('woff'), url('../fonts/Aller-Light-Italic.ttf.svg#Aller-Light-Italic') format('svg'), url('../fonts/Aller-Light-Italic.ttf.eot'), url('../fonts/Aller-Light-Italic.ttf.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Aller';
  src: url('../fonts/Aller.ttf.woff') format('woff'), url('../fonts/Aller.ttf.svg#Aller') format('svg'), url('../fonts/Aller.ttf.eot'), url('../fonts/Aller.ttf.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'icomoon';
  src: url('../fonts//icomoon.eot?ljadqg');
  src: url('../fonts//icomoon.eot?ljadqg#iefix') format('embedded-opentype'), url('../fonts//icomoon.ttf?ljadqg') format('truetype'), url('../fonts//icomoon.woff?ljadqg') format('woff'), url('../fonts//icomoon.svg?ljadqg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto_slabbold';
  src: url('../fonts/RobotoSlab-Bold-webfont.eot');
  src: url('../fonts/RobotoSlab-Bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/RobotoSlab-Bold-webfont.woff') format('woff'), url('../fonts/RobotoSlab-Bold-webfont.ttf') format('truetype'), url('../fonts/RobotoSlab-Bold-webfont.svg#roboto_slabbold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto_slablight';
  src: url('../fonts/RobotoSlab-Light-webfont.eot');
  src: url('../fonts/RobotoSlab-Light-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/RobotoSlab-Light-webfont.woff') format('woff'), url('../fonts/RobotoSlab-Light-webfont.ttf') format('truetype'), url('../fonts/RobotoSlab-Light-webfont.svg#roboto_slablight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'roboto_slabregular';
  src: url('../fonts/RobotoSlab-Regular-webfont.eot');
  src: url('../fonts/RobotoSlab-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/RobotoSlab-Regular-webfont.woff') format('woff'), url('../fonts/RobotoSlab-Regular-webfont.ttf') format('truetype'), url('../fonts/RobotoSlab-Regular-webfont.svg#roboto_slabregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* work-sans-regular - latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/work-sans-v3-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Work Sans'), local('WorkSans-Regular'),
       url('../fonts/work-sans-v3-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/work-sans-v3-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/work-sans-v3-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/work-sans-v3-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/work-sans-v3-latin-ext_latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-700 - latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/work-sans-v3-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Work Sans Bold'), local('WorkSans-Bold'),
       url('../fonts/work-sans-v3-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/work-sans-v3-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/work-sans-v3-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/work-sans-v3-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/work-sans-v3-latin-ext_latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
}

/* cabin-regular - latin-ext_latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/cabin-v12-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Cabin'), local('Cabin-Regular'),
       url('../fonts/cabin-v12-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v12-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v12-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v12-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v12-latin-ext_latin-regular.svg#Cabin') format('svg'); /* Legacy iOS */
}
/* cabin-italic - latin-ext_latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/cabin-v12-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Cabin Italic'), local('Cabin-Italic'),
       url('../fonts/cabin-v12-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v12-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v12-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v12-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v12-latin-ext_latin-italic.svg#Cabin') format('svg'); /* Legacy iOS */
}
/* cabin-700 - latin-ext_latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/cabin-v12-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Cabin Bold'), local('Cabin-Bold'),
       url('../fonts/cabin-v12-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v12-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v12-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v12-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v12-latin-ext_latin-700.svg#Cabin') format('svg'); /* Legacy iOS */
}

/*
Font:     Venti CF
Style:    Medium
URL:    https://www.youworkforthem.com/font/T5312/venti-cf
Foundry:  Connary Fagen
Foundry:  https://www.youworkforthem.com/designer/479/connary-fagen
Copyright:  Copyright © 2015 by Connary Fagen. All rights reserved.
Version:  17
Created:  November 18, 2016
License:  https://www.youworkforthem.com/font-license
License:  The WebFont(s) listed in this document must follow the YouWorkForThem
      WebFont license rules. All other parties are strictly restricted
      from using the WebFonts(s) listed without a purchased license.
      All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: 'VentiCF-Medium';
  src: url('../fonts/VentiCF-Medium.eot');
  src: url('../fonts/VentiCF-Medium.eot?#iefix') format('embedded-opentype'),
             url('../fonts/VentiCF-Medium.woff2') format('woff2'),
       url('../fonts/VentiCF-Medium.woff') format('woff'),
       url('../fonts/venti-cf-medium.ttf') format('truetype'),
       url('../fonts/venti-cf-medium.svg#youworkforthem') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VentiCF-Regular';
  src: url('../fonts/VentiCF-Regular.eot');
  src: url('../fonts/VentiCF-Regular.eot?#iefix') format('embedded-opentype'),
             url('../fonts/VentiCF-Regular.woff2') format('woff2'),
       url('../fonts/VentiCF-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
/*
Font:     Venti CF
Style:    Light
URL:    https://www.youworkforthem.com/font/T5312/venti-cf
Foundry:  Connary Fagen
Foundry:  https://www.youworkforthem.com/designer/479/connary-fagen
Copyright:  Copyright © 2015 by Connary Fagen. All rights reserved.
Version:  17
Created:  November 18, 2016
License:  https://www.youworkforthem.com/font-license
License:  The WebFont(s) listed in this document must follow the YouWorkForThem
      WebFont license rules. All other parties are strictly restricted
      from using the WebFonts(s) listed without a purchased license.
      All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: 'VentiCF-Light';
  src: url('../fonts/VentiCF-Light.eot');
  src: url('../fonts/VentiCF-Light.eot?#iefix') format('embedded-opentype'),
             url('../fonts/VentiCF-Light.woff2') format('woff2'),
       url('../fonts/VentiCF-Light.woff') format('woff'),
       url('../fonts/venti-cf-light.ttf') format('truetype'),
       url('../fonts/venti-cf-light.svg#youworkforthem') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*
Font:     Venti CF
Style:    Bold
URL:    https://www.youworkforthem.com/font/T5312/venti-cf
Foundry:  Connary Fagen
Foundry:  https://www.youworkforthem.com/designer/479/connary-fagen
Copyright:  Copyright © 2015 by Connary Fagen. All rights reserved.
Version:  17
Created:  November 18, 2016
License:  https://www.youworkforthem.com/font-license
License:  The WebFont(s) listed in this document must follow the YouWorkForThem
      WebFont license rules. All other parties are strictly restricted
      from using the WebFonts(s) listed without a purchased license.
      All details above must always remain unaltered and visible in your CSS.
*/

@font-face {
  font-family: 'VentiCF-Bold';
  src: url('../fonts/VentiCF-Bold.eot');
  src: url('../fonts/VentiCF-Bold.eot?#iefix') format('embedded-opentype'),
             url('../fonts/VentiCF-Bold.woff2') format('woff2'),
       url('../fonts/VentiCF-Bold.woff') format('woff'),
       url('../fonts/venti-cf-bold.ttf') format('truetype'),
       url('../fonts/venti-cf-bold.svg#youworkforthem') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VentiCF-ExtraBold';
  src: url('../fonts/VentiCF-ExtraBold.eot');
  src: url('../fonts/VentiCF-ExtraBold.eot?#iefix') format('embedded-opentype'),
             url('../fonts/VentiCF-ExtraBold.woff2') format('woff2'),
       url('../fonts/VentiCF-ExtraBold.woff') format('woff'),
       url('../fonts/VentiCF-ExtraBold.ttf') format('truetype'),
       url('../fonts/VentiCF-ExtraBold.svg#youworkforthem') format('svg');
  font-weight: normal;
  font-style: normal;
}

.icon-copyright:before {
  content: "\e903";
}
.icon-primitive-dot:before {
  content: "\e902";
}
.icon-home6:before {
  content: "\e905";
}
.icon-newspaper:before {
  content: "\e90b";
}
.icon-pencil4:before {
  content: "\e911";
}
.icon-pen3:before {
  content: "\e920";
}
.icon-pen4:before {
  content: "\e921";
}
.icon-blog2:before {
  content: "\e926";
}
.icon-images2:before {
  content: "\e93e";
}
.icon-image4:before {
  content: "\e941";
}
.icon-camera2:before {
  content: "\e945";
}
.icon-headphones:before {
  content: "\e948";
}
.icon-film4:before {
  content: "\e961";
}
.icon-video-camera4:before {
  content: "\e966";
}
.icon-mic:before {
  content: "\e989";
}
.icon-book2:before {
  content: "\e991";
}
.icon-library2:before {
  content: "\e999";
}
.icon-cc:before {
  content: "\e9ec";
}
.icon-ticket:before {
  content: "\e9f9";
}
.icon-phone:before {
  content: "\ea1c";
}
.icon-at-sign:before {
  content: "\ea2f";
}
.icon-envelop3:before {
  content: "\ea32";
}
.icon-location6:before {
  content: "\ea3d";
}
.icon-compass5:before {
  content: "\ea43";
}
.icon-map5:before {
  content: "\ea49";
}
.icon-clock:before {
  content: "\ea4d";
}
.icon-calendar5:before {
  content: "\ea63";
}
.icon-mobile:before {
  content: "\ea78";
}
.icon-bubble-lines2:before {
  content: "\ead2";
}
.icon-user3:before {
  content: "\eb08";
}
.icon-search:before {
  content: "\eb30";
}
.icon-stats-dots:before {
  content: "\eb87";
}
.icon-lamp8:before {
  content: "\ebfa";
}
.icon-bin2:before {
  content: "\ebfe";
}
.icon-download2:before {
  content: "\ec7d";
}
.icon-earth2:before {
  content: "\ec97";
}
.icon-eye:before {
  content: "\ecae";
}
.icon-info2:before {
  content: "\ed64";
}
.icon-cancel-circle2:before {
  content: "\ed66";
}
.icon-checkmark2:before {
  content: "\ed70";
}
.icon-checkmark4:before {
  content: "\ed72";
}
.icon-arrow-up:before {
  content: "\edb7";
}
.icon-arrow-right:before {
  content: "\edbb";
}
.icon-arrow-right4:before {
  content: "\edbe";
}
.icon-arrow-down:before {
  content: "\edbf";
}
.icon-arrow-left:before {
  content: "\edc3";
}
.icon-arrow-left4:before {
  content: "\edc6";
}
.icon-new-tab:before {
  content: "\eec9";
}
.icon-google3:before {
  content: "\eee9";
}
.icon-google-plus2:before {
  content: "\eeeb";
}
.icon-facebook2:before {
  content: "\eef0";
}
.icon-instagram:before {
  content: "\eef1";
}
.icon-rss2:before {
  content: "\eefb";
}
.icon-youtube:before {
  content: "\eefc";
}
.icon-vimeo2:before {
  content: "\ef00";
}
.icon-flickr3:before {
  content: "\ef04";
}
.icon-blogger2:before {
  content: "\ef17";
}
.icon-tumblr2:before {
  content: "\ef19";
}
.icon-skype:before {
  content: "\ef24";
}
.icon-linkedin:before {
  content: "\ef28";
}
.icon-fax:before {
  content: "\e904";
}
.icon-bank:before {
  content: "\e906";
}
.icon-copyright2:before {
  content: "\e907";
}
.icon-twitter-square:before {
  content: "\e901";
}
/* fonts */
/* colours */
/* sizes */
/* buttons */
.big-button {
  display: inline-block;
  font-size: 27px;
  margin: 0 10px;
  padding: 10px 15px;
  border-radius: 6px;
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  -o-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out 0s;
}
@media (max-width: 1199px) {
  .big-button {
    font-size: 23px;
  }
}
@media (max-width: 991px) {
  .big-button {
    font-size: 20px;
  }
}
.red-transparent-button {
  background-color: rgba(229, 0, 65, 0.8);
  color: #fff;
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  -o-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out 0s;
}
.red-transparent-button:hover {
  background-color: #ce7676;
  color: #fff;
}
.red-button {
  background-color: #ce7676;
  color: #fff;
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  -o-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out 0s;
}
.red-button:hover {
  background-color: #1f3b78;
  color: #fff;
}
.white-transparent-button {
  background-color: rgba(255, 255, 255, 0.85);
  color: #444d56;
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  -o-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out 0s;
}
.white-transparent-button:hover {
  background-color: #fff;
  color: #555;
}
.normal-button {
  font-family: 'open_sansregular', arial, sans-serif;
  display: inline-block !important;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 0 10px 10px 0;
}
.breadcrumb {
  padding: 3px 15px 4px 34px;
  list-style: none;
  background-color: #ce7676;
  border-radius: 0;
  clear: both;
  position: relative;
  z-index: 1;
  -webkit-transition: margin 0.35s ease-out;
  -moz-transition: margin 0.35s ease-out;
  -o-transition: margin 0.35s ease-out;
  transition: margin 0.35s ease-out 0s;
}
.breadcrumb a,
.breadcrumb li.active {
  color: #fff;
  font-size: 22px;
}
.breadcrumb li + li:before {
  content: '';
}
.breadcrumb li a,
.breadcrumb li.active,
.breadcrumb li span {
  color: #fff;
}
.breadcrumb li a:before,
.breadcrumb li.active:before,
.breadcrumb li span:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\edbb";
  color: #777;
  color: #fff;
  font-size: 14px;
  padding-right: 13px;
}
.breadcrumb li:first-child a:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e905";
  color: #777;
  font-size: 19px;
  color: #fff;
}
.node-type-line .breadcrumb li {
  display: none;
}
@media (max-width: 868px) {
  .breadcrumb {
    margin-top: 130px;
  }
}
@media (max-width: 767px) {
  .breadcrumb {
    margin-top: 100px;
  }
  .breadcrumb li {
    display: none;
  }
  .iOS .breadcrumb {
    margin-top: 0;
  }
}
.label {
  font-family: 'VentiCF-Medium';
  margin: 0;
  font-size: 1em;
  font-weight: bold;
}
h3.field-label,
h3.pane-title,
.field-label{
  font-family: 'VentiCF-Medium';
  margin: 0;
  font-size: 1em;
  font-weight: bold;
}
h3.pane-title {
  line-height: 2.5;
}

.field-name-field-contacts .description {
    padding: 0px 0 10px 45px;
    background: #f9f9f9;
    font-size: 16px;
}

.section {
  position: relative;
  z-index: 1;
  padding: 40px 0 30px;
}
.section.intro {
  padding: 60px 0;
}
.section.links {
  background-color: #fbfcfe;
  border-bottom: 2px solid #ecedef;
}
.section.supporters {
  background-color: #fff;
}
.section.legal {
  background-color: #ecedef;
}
html {
  overflow-x: hidden;
}
body {
  font-family: 'VentiCF-Medium', arial, sans-serif;
  font-size: 19px;
  line-height: 1.5;
  overflow-x: hidden;
  color: #525f7b;
}

body.page-administration {
    font-family: 'open_sansregular';
    letter-spacing: -0.03em;
}

p, ol, ul{
  font-size: 19px;
  overflow-wrap: break-word;
}


p {
    line-height: 1.3;
    margin-bottom: 1em;
}


sub, sup {
    position: relative;
    font-size: 60%;
    line-height: 0;
    vertical-align: baseline;
    padding: 3px;
}

h1,
h2,
h3,
h4 {
  font-family: 'VentiCF-Bold', arial, sans-serif;
  margin-top:0;
}


h1.page-header {
  color: #1f3b78;
  font-size: 30px;
  margin: 28px 0 14px;
  border: none;
}
.ui-widget-content a,
a {
color: #647cc5;
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  -o-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out 0s;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #bb4e23;
}

ul,
ol {
  line-height: 1.5;
  margin-bottom: 1.5em;
}
img {
  max-width: 100%;
}
.bordered-image {
  width: 100%;
  padding: 5px;
  border: 1px solid #aaaaaa;
}
hr {
  border-top: 2px solid #444d56;
}
.entity-paragraphs-item {
  margin-bottom: 40px;
  clear: both;
}
.entity-paragraphs-item p ul {
  list-style-type: none;
  padding-left: 0;
}
.entity-paragraphs-item p ul li {
  position: relative;
  padding-left: 25px;
  margin-bottom: 10px;
}
.entity-paragraphs-item p ul li:before {
  position: absolute;
  top: 2px;
  left: 0;
  font-family: icomoon;
  content: "\e902";
  font-size: 25px;
  color: #e50041;
  line-height: 1;
}
.node-view .entity-paragraphs-item .field-name-field-caption,
.node-view .field-name-field-caption,
.node-view .field-name-field-image-1-caption,
.node-view .field-name-field-image-2-caption,
.node-view .field-name-field-image-3-caption {
    padding: 6px 10px;
    background-color: #aaaaaa;
    font-size: 0.85em;
    color: #fff;
    line-height: 1.2;
    margin-top: -10px;
}

.node-view .pane-node-field-requirements {
    margin-top: 30px;
}

.node-view .pane-node-field-how-to-apply .field-label{
      margin-bottom: 20px;
}

.search-result {
    margin-bottom: 30px;
}


.entity-paragraphs-item h2 {

}
@media (max-width: 640px) {
  .entity-paragraphs-item h2 {
    margin-top: 20px;
  }
}
.entity-paragraphs-item .field-name-field-button .field-item {
  display: inline-block;
}
.entity-paragraphs-item .field-name-field-button a {
  display: inline-block;
  font-size: 27px;
  margin: 0 10px;
  padding: 10px 15px;
  border-radius: 6px;
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  -o-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out 0s;
  background-color: #ce7676;
  color: #fff;
  margin: 0 20px 0 0;
}
@media (max-width: 1199px) {
  .entity-paragraphs-item .field-name-field-button a {
    font-size: 23px;
  }
}
@media (max-width: 991px) {
  .entity-paragraphs-item .field-name-field-button a {
    font-size: 20px;
  }
}
.entity-paragraphs-item .field-name-field-button a :hover {
  background-color: #1f3b78;
}
.entity-paragraphs-item.paragraphs-item-document,
.entity-paragraphs-item.paragraphs-item-audio {
  padding: 10px;
  border: 1px solid #ccc;
}
.entity-paragraphs-item.paragraphs-item-2-column-text,
.entity-paragraphs-item.paragraphs-item-text-and-left-image,
.entity-paragraphs-item.paragraphs-item-text-and-right-image {
  display: table;
  width: 100%;
}
.entity-paragraphs-item.paragraphs-item-2-column-text img,
.entity-paragraphs-item.paragraphs-item-text-and-left-image img,
.entity-paragraphs-item.paragraphs-item-text-and-right-image img,
.node-type-profile .pane-node-field-image img,
.node-type-article .pane-node-field-image img,
.node-type-institution .pane-node-field-image img,
.node-type-event .pane-node-field-image img,
.pane-node-field-image-2 img,
.pane-node-field-image-3 img {
  padding: 5px;
  border: 1px solid #aaaaaa;
}

.paragraphs-item-single-image-and-text .field-name-field-trifold-image-1,
.paragraphs-item-single-image-and-text .textarea{
  margin-bottom:30px;
}

@media (min-width: 640px) {
  .entity-paragraphs-item.paragraphs-item-2-column-text .field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-2-column-text .field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-2-column-text .textspaceleft,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .textspaceleft,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .textspaceleft,
  .entity-paragraphs-item.paragraphs-item-2-column-text .textspaceright,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .textspaceright,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .textspaceright {
    display: table-cell;
    width: 50%;
    vertical-align: top;
  }
  .entity-paragraphs-item.paragraphs-item-2-column-text .field-name-field-left-text.field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .field-name-field-left-text.field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .field-name-field-left-text.field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-2-column-text .field-name-field-right-text.field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .field-name-field-right-text.field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .field-name-field-right-text.field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-2-column-text .textspaceleft.field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .textspaceleft.field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .textspaceleft.field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-2-column-text .textspaceright.field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .textspaceright.field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .textspaceright.field-name-field-left-text,
  .entity-paragraphs-item.paragraphs-item-2-column-text .field-name-field-left-text.image-left,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .field-name-field-left-text.image-left,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .field-name-field-left-text.image-left,
  .entity-paragraphs-item.paragraphs-item-2-column-text .field-name-field-right-text.image-left,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .field-name-field-right-text.image-left,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .field-name-field-right-text.image-left,
  .entity-paragraphs-item.paragraphs-item-2-column-text .textspaceleft.image-left,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .textspaceleft.image-left,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .textspaceleft.image-left,
  .entity-paragraphs-item.paragraphs-item-2-column-text .textspaceright.image-left,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .textspaceright.image-left,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .textspaceright.image-left,
  .entity-paragraphs-item.paragraphs-item-2-column-text .field-name-field-left-text.field-name-field-text,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .field-name-field-left-text.field-name-field-text,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .field-name-field-left-text.field-name-field-text,
  .entity-paragraphs-item.paragraphs-item-2-column-text .field-name-field-right-text.field-name-field-text,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .field-name-field-right-text.field-name-field-text,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .field-name-field-right-text.field-name-field-text,
  .entity-paragraphs-item.paragraphs-item-2-column-text .textspaceleft.field-name-field-text,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .textspaceleft.field-name-field-text,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .textspaceleft.field-name-field-text,
  .entity-paragraphs-item.paragraphs-item-2-column-text .textspaceright.field-name-field-text,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .textspaceright.field-name-field-text,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .textspaceright.field-name-field-text {
    padding-right: 25px;
  }
  .entity-paragraphs-item.paragraphs-item-2-column-text .field-name-field-left-text.field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .field-name-field-left-text.field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .field-name-field-left-text.field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-2-column-text .field-name-field-right-text.field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .field-name-field-right-text.field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .field-name-field-right-text.field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-2-column-text .textspaceleft.field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .textspaceleft.field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .textspaceleft.field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-2-column-text .textspaceright.field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .textspaceright.field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .textspaceright.field-name-field-right-text,
  .entity-paragraphs-item.paragraphs-item-2-column-text .field-name-field-left-text.image-right,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .field-name-field-left-text.image-right,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .field-name-field-left-text.image-right,
  .entity-paragraphs-item.paragraphs-item-2-column-text .field-name-field-right-text.image-right,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .field-name-field-right-text.image-right,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .field-name-field-right-text.image-right,
  .entity-paragraphs-item.paragraphs-item-2-column-text .textspaceleft.image-right,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .textspaceleft.image-right,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .textspaceleft.image-right,
  .entity-paragraphs-item.paragraphs-item-2-column-text .textspaceright.image-right,
  .entity-paragraphs-item.paragraphs-item-text-and-left-image .textspaceright.image-right,
  .entity-paragraphs-item.paragraphs-item-text-and-right-image .textspaceright.image-right {
    padding-left: 25px;
  }
}
.entity-paragraphs-item.paragraphs-item-2-column-text .image-left,
.entity-paragraphs-item.paragraphs-item-text-and-left-image .image-left,
.entity-paragraphs-item.paragraphs-item-text-and-right-image .image-left,
.entity-paragraphs-item.paragraphs-item-2-column-text .image-right,
.entity-paragraphs-item.paragraphs-item-text-and-left-image .image-right,
.entity-paragraphs-item.paragraphs-item-text-and-right-image .image-right,
.entity-paragraphs-item.paragraphs-item-2-column-text .field-name-field-text,
.entity-paragraphs-item.paragraphs-item-text-and-left-image .field-name-field-text,
.entity-paragraphs-item.paragraphs-item-text-and-right-image .field-name-field-text {
  display: block;
}
.entity-paragraphs-item.paragraphs-item-2-column-text .image-left,
.entity-paragraphs-item.paragraphs-item-text-and-left-image .image-left,
.entity-paragraphs-item.paragraphs-item-text-and-right-image .image-left {
  float: left;
  width: 51%;
  padding: 0 25px 20px 0;
}
.entity-paragraphs-item.paragraphs-item-2-column-text .image-right,
.entity-paragraphs-item.paragraphs-item-text-and-left-image .image-right,
.entity-paragraphs-item.paragraphs-item-text-and-right-image .image-right {
  float: right;
  width: 51%;
  padding: 0 0 20px 25px;
}
.entity-paragraphs-item.paragraphs-item-image-grid {
  display: table;
}
.entity-paragraphs-item.paragraphs-item-image-grid img {
  float: left;
  width: 226px;
  margin: 1px;
}
.entity-paragraphs-item.paragraphs-item-quote {
  font-family: 'VentiCF-Light', arial, sans-serif;
  border-top: 13px solid #79b19f;
  border-bottom: 3px solid #79b19f;
  padding-bottom: 10px;
}
.entity-paragraphs-item.paragraphs-item-quote .field-name-field-quotation {
  font-size: 2em;
}
.entity-paragraphs-item.paragraphs-item-quote .field-name-field-by {
  font-size: 1.2em;
  color: #62a58f;
}
.entity-paragraphs-item.paragraphs-item-call-to-action,
.entity-paragraphs-item.paragraphs-item-call-to-action-with-background-p {
  text-align: center;
  padding: 25px 30px 45px 30px;
}
.entity-paragraphs-item.paragraphs-item-call-to-action .field-name-field-description-header,
.entity-paragraphs-item.paragraphs-item-call-to-action-with-background-p .field-name-field-description-header {
  font-family: 'VentiCF-Light', arial, sans-serif;
  font-size: 46px;
  padding-bottom: 5px;
}
.entity-paragraphs-item.paragraphs-item-call-to-action .field-name-field-description,
.entity-paragraphs-item.paragraphs-item-call-to-action-with-background-p .field-name-field-description {
  font-size: 16px;
  padding-bottom: 30px;
}
.entity-paragraphs-item.paragraphs-item-call-to-action.paragraphs-item-call-to-action,
.entity-paragraphs-item.paragraphs-item-call-to-action-with-background-p.paragraphs-item-call-to-action {
  background-color: #edeef0;
}
.entity-paragraphs-item.paragraphs-item-call-to-action.paragraphs-item-call-to-action .field-name-field-description-header,
.entity-paragraphs-item.paragraphs-item-call-to-action-with-background-p.paragraphs-item-call-to-action .field-name-field-description-header {
  color: #1f3b78;
}
.entity-paragraphs-item.paragraphs-item-call-to-action.paragraphs-item-call-to-action-with-background-p,
.entity-paragraphs-item.paragraphs-item-call-to-action-with-background-p.paragraphs-item-call-to-action-with-background-p {
  color: #fff;
  position: relative;
  overflow: hidden;
}
.entity-paragraphs-item.paragraphs-item-call-to-action.paragraphs-item-call-to-action-with-background-p img,
.entity-paragraphs-item.paragraphs-item-call-to-action-with-background-p.paragraphs-item-call-to-action-with-background-p img {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  padding: 0;
  border: none;
}
@media (max-width: 991px) {
  .entity-paragraphs-item.paragraphs-item-call-to-action.paragraphs-item-call-to-action-with-background-p img,
  .entity-paragraphs-item.paragraphs-item-call-to-action-with-background-p.paragraphs-item-call-to-action-with-background-p img {
    width: auto;
    height: 100%;
    max-width: none;
  }
}
.entity-paragraphs-item.paragraphs-item-call-to-action.paragraphs-item-call-to-action-with-background-p .contents,
.entity-paragraphs-item.paragraphs-item-call-to-action-with-background-p.paragraphs-item-call-to-action-with-background-p .contents {
  z-index: 3;
  position: relative;
  width: 100%;
}
.entity-paragraphs-item.paragraphs-item-call-to-action.paragraphs-item-call-to-action-with-background-p .field-name-field-description-header,
.entity-paragraphs-item.paragraphs-item-call-to-action-with-background-p.paragraphs-item-call-to-action-with-background-p .field-name-field-description-header {
  color: #fff;
}
.paragraphs-item-text-and-left-image.entity-paragraphs-item .field-name-field-pagelink {
  padding: 20px 0 0 20px;
}
.paragraphs-item-text-and-right-image.entity-paragraphs-item .field-name-field-pagelink {
  padding: 20px 20px 0 0;
}

.paragraphs-item-4-way-image-links img {
    width: 50%;
    float: left;
}


#panel-bootstrap-region-image {
    padding-top: 10px;
}


.page-node-add .text-format-wrapper .filter-wrapper,
.page-node-edit .text-format-wrapper .filter-wrapper {
  display: none;
}

.page-node-add .container-inline-date,
.page-node-edit .container-inline-date {
  margin-bottom: 0;
}
.page-node-add .chosen-container,
.page-node-edit .chosen-container {
  width: 100% !important;
  background: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
  margin-top: 6px;
}

.date-form-element-content-multiline {
    padding: 0;
    border: none;
}

.page-node-add .container-inline-date .date-padding,
.page-node-edit .container-inline-date .date-padding {
    float: none;
}


.chosen-container-multi .chosen-choices {
    position: relative;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 3px 8px;
    border-radius: 6px;
    width: 100%;
    height: auto !important;
    height: 1%;
    border: 1px solid #ccc;
    background-color: #fff;
    background-image: none;
    cursor: text;
}


.chosen-container#edit_field_country_und_chosen{
     background: transparent;
}

.page-node-add .panelgroup,
.page-node-edit .panelgroup {
  background: #f3f2e9;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid #e2cbcb;
}

.page-node-add .panelgroup .panelgroup,
.page-node-edit .panelgroup .panelgroup{
    background: #f7f7f5;
    box-shadow: 0px 1px 3px 0px #c5bbbb;
    border: white;
}

.page-node-add label,
.page-node-edit label {
  font-family: 'VentiCF-Medium';
  margin: 0;
  font-size: 1em;
  font-weight: bold;
}
.page-node-add .no-frontpage .form-item-promote,
.page-node-edit .no-frontpage .form-item-promote,
.page-node-add .no-sticky .form-item-sticky,
.page-node-edit .no-sticky .form-item-sticky {
  display: none;
}
.page-node-add .image-preview,
.page-node-edit .image-preview {
  float: none;
}

.page-node-add.node-type-profile .image-preview, 
.page-node-edit.node-type-profile .image-preview {
    float: left;
    margin-right: 15px;
}


.page-node-add .button,
.page-node-edit .button {
  font-family: 'open_sansregular', arial, sans-serif;
  display: inline-block !important;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 0 10px 10px 0;
}
.page-node-add .button.browse,
.page-node-edit .button.browse,
.page-node-add .button.linkit-field-button,
.page-node-edit .button.linkit-field-button {
  background: #5bc0de;
  color: #fff;
}
.page-node-add .button.browse:before,
.page-node-edit .button.browse:before,
.page-node-add .button.linkit-field-button:before,
.page-node-edit .button.linkit-field-button:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ecae";
  color: #777;
  color: #fff;
}
.page-node-add .button.browse:hover,
.page-node-edit .button.browse:hover,
.page-node-add .button.linkit-field-button:hover,
.page-node-edit .button.linkit-field-button:hover {
  background: #31b0d5;
}
.page-node-add .button.linkit-field-button,
.page-node-edit .button.linkit-field-button {
  margin-top: 10px;
}
.page-node-add .button.edit,
.page-node-edit .button.edit {
  background: #5bc0de;
  color: #fff;
}
.page-node-add .button.edit:before,
.page-node-edit .button.edit:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e911";
  color: #777;
  color: #fff;
}
.page-node-add .button.edit:hover,
.page-node-edit .button.edit:hover {
  background: #31b0d5;
}
.page-node-add .pane-node-form-comment .pane-title,
.page-node-edit .pane-node-form-comment .pane-title {
  font-family: 'VentiCF-Medium';
  margin: 0;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 5px;
}
.btn {
  margin: 0 10px 10px 0;
  font-family: 'open_sansregular', arial, sans-serif !important;
}

.input-group-btn .btn {
padding:11px;
}
h2.ctools-collapsible-handle {
  font-family: 'open_sansregular', arial, sans-serif;
  display: inline-block !important;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 0 10px 10px 0;
  background: #5bc0de;
  color: #fff;
}
h2.ctools-collapsible-handle:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\edbf";
  color: #777;
  color: #fff;
}
h2.ctools-collapsible-handle:hover {
  background: #31b0d5;
}
.ctools-toggle {
  display: none;
}
.addtocal {
  font-family: 'open_sansregular', arial, sans-serif;
  display: inline-block !important;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 0 10px 10px 0;
  float: none;
  display: inline-block;
  margin: 10px;
  background: #5bc0de;
  color: #fff;
}
.addtocal:hover {
  background: #31b0d5;
}
.addtocal:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\edbf";
  color: #777;
  color: #fff;
}
.panel-pane {
  margin-bottom: 10px;
}
.field-group-colorbox-trigger {
  outline: none;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 6px;
  font-size: 0;
}
.field-group-colorbox-trigger:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ed64";
  color: #777;
  font-size: 22px;
  color: #fff;
  text-shadow: 0 0 4px #000;
}
.ui-accordion {
  position: relative;
}
.ui-accordion .ui-accordion-header {
  cursor: pointer;
  padding: .5em .5em .5em .7em;
  min-height: 0;
  border: 0;
  background: none;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
}
.ui-accordion .ui-accordion-header-icon {
  display: none;
}
.ui-accordion .field-group-format-toggler a {
  font-size: 0;
}
.ui-accordion .field-group-format-toggler a:before {
    font-family: 'icomoon';
    padding: 2px;
    content: "\ed64";
    font-size: 22px;
    color: #2965e4;
    background: white;
    border-radius: 100%;
}
.ui-accordion .field-label {
  font-family: 'VentiCF-Medium';
  margin: 0;
  font-size: 1em;
  font-weight: bold;
  float: left;
}
.ctools-modal-dialog .tabledrag-toggle-weight-wrapper,
.ctools-modal-dialog #field-discipline-values .chosen-container,
.ctools-modal-dialog .field-name-field-infobutton,
.ctools-modal-dialog .tabs-left,
.ctools-modal-dialog .form-item-files-replace-upload {
  display: none !important;
}
.ui-widget {
  font-family: inherit;
  font-size: inherit;
  border: 0;
  padding: 0;
}
.ui-widget-header {
  border: 0;
  background: #fff;
  color: #222222 /*{fcHeader}*/;
  font-weight: bold;
}
.ui-widget-header .ui-state-default {
  border: 1px solid #ddd;
  background: #ddd !important;
}
.ui-widget-header .ui-state-active {
  background: #fff !important;
}
.ui-widget-header .ui-state-active a {
  outline: none;
}
.ui-tabs .ui-tabs-panel {
  padding: 0;
}
.chosen-container {
  border: 0;
  box-shadow: none;
  padding: 0;
}
.chosen-container-single .chosen-single,
#edit-type-selective,
#edit-field-institution-target-id-selective,
#edit-status-selective,
#edit-field-topics-tid,
#edit-shs-term-node-tid-depth-select-1,
#edit-field-country-tid-selective,
#edit-field-country-tid-selective,
#edit-field-primary-institution-tid-selective{
    background: #fff;
    box-shadow: none;
    padding: 3px 10px 6px;
    height: auto;
    font-size: 18px;
    border-color: #ccc;
}

.chosen-container.form-control {
    padding: 0;
}

.btn-group-vertical > .btn {
  margin: 0;
}
.view-gallery .file-title,
.view-gallery .photographer,
.view-gallery .copyright,
.view-gallery .cc {
  padding: 0px 8px;
  background: #eee;
  line-height: 26px;
}
.view-gallery .photographer:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e945";
  color: #777;
}
.view-gallery .copyright:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e903";
  color: #777;
}
.view-gallery .cc:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\eec9";
  color: #777;
}
.view-gallery .cc a:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ec7d";
  color: #777;
}
.view-gallery .file-title {
  background: #eee;
  margin-top: 20px;
}

.edit-link,
.delete-link{
	width:70px;
}

.edit-link a:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e911";
  color: #33d821;
}
.delete-link a:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ebfe";
  color: #e41111;
}
.node-view .pane-node-field-email .pane-content:before,
.node-view .field-name-field-email .field-item:before,
.view-article-contact-detail .field-name-field-email .field-item:before,
.more-contacts .field-name-field-email:before{
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ea2f";
  color: #c7ba54;
}
.node-view .pane-node-field-address .pane-content p,
.node-view .field-name-field-address p,
.view-article-contact-detail .field-name-field-address p{
  margin-left: 25px;
  text-indent: -25px;
  margin-bottom: 0 !important;
}


.node-view .pane-node-field-address .pane-content p:before,
.node-view .field-name-field-address p:before,
.view-article-contact-detail .field-name-field-address p:before{
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ea43";
  color: #c7ba54;
  padding-right: 9px;
}
.node-view .pane-node-field-contact-person .pane-content:before,
.node-view .field-name-field-contact-person .field-item:before,
.view-article-contact-detail .field-name-field-contact-person .field-item:before,
.more-contacts .field-name-field-contact-person:before{
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\eb08";
  color: #c7ba54;
}

.node-view .pane-node-field-phone .pane-content:before, 
.node-view .field-name-field-telephone .field-item:before, 
.view-article-contact-detail .field-name-field-telephone .field-item:before, 
.more-contacts .field-name-field-telephone:before {
    font-family: 'icomoon';
    padding-right: 6px;
    content: "\ea1c";
    color: #c7ba54;
}


.node-view .pane-node-field-phone .pane-content:before,
.node-view .pane-node-field-telephone .pane-content:before,
.node-view .field-name--field-phone .field-item:before,
.node-view .field-name-field-telephone .field-item:before,
.view-article-contact-detail .field-name-field-telephone .field-item:before,
.view-article-contact-detail .field-name-field-phone .field-item:before,
.more-contacts .field-name-field-telephone:before,
.more-contacts .field-name-field-phone:before  {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ea1c";
  color: #c7ba54;
}

.field-name-field-phone{
    display: inline-block;
}

.node-view .pane-node-field-institution .pane-content:before,
.node-view .field-name-field-institution .field-item:before,
.node-view .pane-node-field-venue .pane-content:before{
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e999";
  color: #c7ba54;
  float: left;
}

.node-type-job.node-view .pane-node-field-institution .pane-content:before
{
display:none;
}


.node-view .field-name-field-contact-mobile .field-item:before,
.more-contacts .field-name-field-mobile:before,
.node-view .pane-node-field-mobile .pane-content:before{
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ea78";
  color: #c7ba54;
}

.node-view .pane-node-field-location .pane-content:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ea3d";
  color: #c7ba54;
}

.node-view .pane-node-field-country .pane-content:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ec97";
  color: #c7ba54;
}
.node-view .pane-node-field-country .pane-content:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ec97";
  color: #c7ba54;
}

.node-type-article.node-view .pane-node-field-country .pane-content:before {
display:none;
}
.node-view .pane-node-field-timezone .pane-content:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ea4d";
  color: #c7ba54;
}


.node-view .pane-node-field-facebook .pane-content:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\eef0";
  color: #c7ba54;
}
.node-view .pane-node-field-twitter .pane-content:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e901";
  color: #c7ba54;
  font-size: 120%;
}
.node-view .pane-node-field-linkedin .pane-content:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ef28";
  color: #c7ba54;
}
.node-view .pane-node-field-researchgate .pane-content:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\eec9";
  color: #c7ba54;
}
.node-view .pane-node-field-researcherid .pane-content:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\eec9";
  color: #c7ba54;
}

.node-view .jobsdetails .pane-node-field-institution .pane-content:before{
display:none;
}

.node-view .pane-profile-contacts{
    margin-top:20px;
    padding-top:20px;
    Border-top:3px solid #ccc;
}


.node-view .ctools-collapsible-content {
  margin-bottom: 20px;
}
.node-view .ctools-collapsible-content .field-label {
  display: none;
}
.sm2-inline-list {
  padding: 14px 68px;
  background: #eae9e9;
}

.media-wrapper {
  padding: 20px;
  overflow-x: visible;
}
.carousel-text {
  text-align: center;
  position: absolute;
  top: 45%;
  width: 100%;
  padding: 0 10%;
  font-family: 'VentiCF-Medium', arial, sans-serif;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.carousel-text p {
  font-size: inherit;
  line-height: 1.1;
  font-size: 51px !important;
  color: #fff;
  text-shadow: 0px 0px 4px #000;
}

.view-featured-accomplishment .view-header h3,
.view-admin-events .view-header h3 {
    margin-top: -15px;
    margin-bottom: 15px;
}

.view-front-page-events.view-display-id-news_top_panel {
  margin-bottom: 20px;
}
.view-front-page-events a {
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  -o-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out 0s;
  color: #408c6c;
}
.view-front-page-events a:hover {
  color: #842bc7;
}
.view-front-page-events a:hover .news-taxonomy,
.view-front-page-events a:hover .event-date {
  background: #e4e5e0;
}
.view-front-page-events a:hover .news-description,
.view-front-page-events a:hover .event-description {
  background: #eee;
}
.view-front-page-events a:hover img {
  filter: brightness(110%);
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  -o-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out 0s;
}
.view-front-page-events a .news-taxonomy,
.view-front-page-events a .event-date,
.view-front-page-events a .news-description,
.view-front-page-events a .event-description {
  color: #525f7b;
  -webkit-transition: all 0.35s ease-out;
  -moz-transition: all 0.35s ease-out;
  -o-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out 0s;
}
.view-front-page-events .views-field-title,
.view-featured-accomplishment .views-field-title,
.view-admin-events.view-display-id-panel_pane_2 .views-field-title,
.view-profiles-land-page.view-display-id-panel_pane_2 .views-field-nothing .name,
.view-profiles-land-page.view-display-id-panel_pane_4 .views-field-nothing .name,
.view-profiles-land-page.view-display-id-panel_pane_3 .views-field-nothing .views-field-title,
.view-events-sidebar.view-display-id-panel_pane_1 .views-field-title,
.view-events-land-page .views-field-title,
.view-institutions-landing-page .views-field-nothing .name,
.view-institutions-landing-page.view-display-id-panel_pane_2 .views-field-title{
    font-family: VentiCF-Bold;
    font-size: 26px;
    line-height: 1;
    color: #333;
    margin-bottom: 8px;
}

.view-all-events-past td {
    min-width: 136px;
}

.view-institutions-landing-page.view-display-id-panel_pane_2 img{
    margin-bottom:15px;
}

.view-institutions-landing-page.view-display-id-panel_pane_2{
    margin-top:30px;
}

.view-profiles-land-page a,
.view-events-sidebar a,
.view-events-land-page a,
.view-institutions-landing-page a{
    color:#333;
}

.view-profiles-land-page a:hover,
.view-events-sidebar a:hover,
.view-events-land-page a:hover{
    color:#79b19f;
}
.view-profiles-land-page.view-display-id-panel_pane_4{
    margin-top:40px;
}
.view-profiles-land-page.view-display-id-panel_pane_4 .views-field-nothing .description,
.view-events-sidebar.view-display-id-panel_pane_1 .description{
    color:#777;
}

.view-events-sidebar.view-display-id-panel_pane_1 .date-display-single,
.view-events-sidebar.view-display-id-panel_pane_1 .date-display-range,
.view-events-land-page .date-display-single,
.view-events-land-page .date-display-range{
    font-family: VentiCF-Bold;
    margin-bottom:20px;
    font-size: 18px;
    color: #222;
    display: block;
}

.view-profiles-land-page.view-display-id-panel_pane_2 .views-field-nothing,
.view-events-land-page.view-display-id-panel_pane_1 .views-field-nothing,
.view-institutions-landing-page.view-display-id-panel_pane_4 .views-field-nothing{
    display: table;
    height: 450px;
}

.view-profiles-land-page.view-display-id-panel_pane_2 .views-field-nothing .field-content,
.view-events-land-page.view-display-id-panel_pane_1 .views-field-nothing .field-content,
.view-institutions-landing-page.view-display-id-panel_pane_4 .views-field-nothing .field-content{
    display: table-cell;
    vertical-align:middle;
}

.view-profiles-land-page.view-display-id-panel_pane_2 .views-field-nothing .description,
.view-events-land-page.view-display-id-panel_pane_1 .views-field-nothing .date-display-range,
.view-institutions-landing-page.view-display-id-panel_pane_4 .views-field-nothing .description
{
    font-size: 23px;
    border-bottom:2px solid #333;
    padding-bottom:30px;
}

.view-profiles-land-page.view-display-id-panel_pane_2 .views-field-nothing .name,
.view-events-land-page.view-display-id-panel_pane_1 .views-field-nothing .views-field-title,
.view-institutions-landing-page.view-display-id-panel_pane_4 .views-field-nothing .name{
    border-top:2px solid #333;
    padding-top:30px;
}

.view-profiles-land-page.view-display-id-panel_pane_3 .views-field-nothing{
    margin-top:30px;
}

.view-profiles-land-page.view-display-id-panel_pane_4 td.views-field.views-field-nothing {
    vertical-align: middle;
}

.pane-events-landing-page-2-3{
    margin-top:30px;
}

.page-user-signup .form-item-field-institution-checklist-und {
    margin-bottom: 30px;
    padding: 10px;
    border: 1px solid #ccc;
    background: #eaeae
}


.page-user-signup #edit_field_institution_checklist_und_chosen {
    width: 100% !important;
}


.page-user-signup .form-item-name:after {
    content: "For added security, we will send you an email with a link to set your password. Please lookout for this email to complete your registration.";
    display: block;
}


.page-user-signup .form-item-conf-mail:after {
    content: "Remember to click on the link in the email to complete your registration.";
    display: block;
}

.page-user-signup-8 #edit-actions:before {
    content: "Please look out for the email with a link to create your Institution profile";
    display: block;
    margin-bottom: 12px;
}

.view-front-page-events.view-display-id-panel_pane_3 .views-field-title a {
font-family: VentiCF-Medium;
    font-size: 23px;
    line-height: 1;
    color: #333;
    margin-bottom: 19px;
    display: block;
    margin-left: 33px;
}
.view-front-page-events .views-field_institutions,
.view-featured-accomplishment .views-field_institutions,
.view-admin-events.view-display-id-panel_pane_2 .date-display-range{
    font-family: VentiCF-Bold;
    font-size: 18px;
    color: #888888;
    margin-bottom:10px;
}


.view-front-page-events.view-display-id-panel_pane_3 .views-field-title .field-content:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: #88888c;
    float:left;
    margin-right: 12px;
    margin-top: 4px;
}

.pane-front-page-events-panel-pane-3 {
    margin-top: 82px;
}

.pane-front-page-events-panel-pane-3 h2.pane-title {
    font-size: 40px;
}

.view-front-page-events.view-display-id-panel_pane_2 .views-field-nothing{
    margin-bottom:20px;
}


.view-front-page-events.view-display-id-panel_pane_1 {
    margin-bottom: 27px;
}
.view-front-page-events .news-taxonomy,
.view-front-page-events .event-date {
  background: #eee;
  padding: 5px 10px;
  font-size: 12px;
}

.title-inst {
    height: 90px;
}
.view-front-page-events .news-description,
.view-front-page-events .event-description {
  padding: 5px 10px;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  background: #e4e5e0;
  height: 98px;
  overflow: hidden;
  font-size: 15px;
}
.view-front-page-events .small-news {
  background: #eee;
  height: 125px;
  overflow: hidden;
}
.view-front-page-events .small-news-title {
  padding: 5px 10px;
  background: #eee;
  font-family: 'VentiCF-Light';
}
.view-front-page-events .Science {
  border-top: 3px solid #bca0da;
}
.view-front-page-events .Society {
  border-top: 3px solid #aece60;
}
.view-front-page-events .Health {
  border-top: 3px solid #6ac5ca;
}
.view-front-page-events .Science-Society {
  border-top: 3px solid #bca0da;
}
.view-front-page-events .Science-Society:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 45%;
  border-top: 3px solid #aece60;
}
.view-front-page-events .Health-Society {
  border-top: 3px solid #6ac5ca;
}
.view-front-page-events .Health-Society:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 45%;
  border-top: 3px solid #aece60;
}
.view-front-page-events .Science-Health,
.view-front-page-events .Health-Science {
  border-top: 3px solid #6ac5ca;
}
.view-front-page-events .Science-Health:before,
.view-front-page-events .Health-Science:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 45%;
  border-top: 3px solid #bca0da;
}
.nav > li {
  position: relative;
  display: inline-block;
}
#articles h2:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e90b";
  color: #777;
}
#events h2:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ea63";
  color: #777;
}
#jobs h2:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\ea4d";
  color: #777;
}


#researchers h2:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\eb08";
  color: #777;
}

#jobs h2,
#articles h2,
#events h2,
#researchers h2 {
font-size:30px;
}
.bef-select-as-links a {
  color: #fff;
   padding: 3px 12px;
   display: block;
}
.bef-select-as-links .form-type-bef-link {
  display: inline-block !important;
  padding: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 2px solid transparent;
  border-radius: 4px;
  margin: 0 10px 10px 0;
  font-family: venticf-bold;
}
.bef-select-as-links .form-type-bef-link:hover {
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out 0s;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-all {
  border: 1px solid #79b19f;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-all a{
  color:#79b19f;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-all a:hover{
  color:#fff;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-all:hover{
  background-color:#79b19f;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-9 {
  border: 1px solid #6ac5ca;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-9 a{
  color: #6ac5ca;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-9 a:hover{
  color:#fff;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-9:hover{
  background-color:#6ac5ca;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-8 {
  border: 1px solid #bca0da;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-8 a{
  color: #bca0da;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-8 a:hover{
  color:#fff;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-8:hover{
  background-color:#bca0da;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-10 {
  border: 1px solid #aece60;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-10 a{
  color: #aece60;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-10 a:hover{
  color:#fff;
}
.bef-select-as-links .form-item-edit-field-subject-area-tid-10:hover{
  background-color:#aece60;
}

.btn-info {
    color: #5bc0de;
    background-color: #fff;
    border-color: #46b8da;
    font-size: 18px;
    line-height: 1.2;
}

.btn-default {
    color: #aaa;
    background-color: #fff;
    border-color: #aaa;
    font-size: 18px;
    line-height: 1.2;
}

.view-articles-panel .Society .thumb:before,
.view-articles-panel .Health .thumb:before,
.view-articles-panel .Science .thumb:before,
.view-articles-panel .Science-Society .thumb:before,
.view-articles-panel .Health-Society .thumb:before,
.view-articles-panel .Health-Science .thumb:before {
  font-size: 35px;
  margin-left: 0px;
  float: left;
  margin-top: -13px;
  display: block;
  height: 35px;
}
.view-articles-panel .Science-Society .thumb a:before,
.view-articles-panel .Health-Society .thumb a:before,
.view-articles-panel .Health-Science .thumb a:before {
  margin-left: -23px;
  font-size: 35px;
  display: inline-block;
  width: 9px;
  float: left;
  overflow: hidden;
  z-index: 5;
  position: relative;
  margin-top: -13px;
  border-right: 1px solid #fff;
  display: block;
  height: 35px;
}
.view-articles-panel .Science .thumb:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e902";
  color: #777;
  color: #bca0da;
}
.view-articles-panel .Health .thumb:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e902";
  color: #777;
  color: #6ac5ca;
}
.view-articles-panel .Society .thumb:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e902";
  color: #777;
  color: #aece60;
}
.view-articles-panel .Science-Society .thumb:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e902";
  color: #777;
  color: #aece60;
}
.view-articles-panel .Science-Society .thumb a:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e902";
  color: #777;
  color: #bca0da;
}
.view-articles-panel .Health-Society .thumb:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e902";
  color: #777;
  color: #aece60;
}
.view-articles-panel .Health-Society .thumb a:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e902";
  color: #777;
  color: #6ac5ca;
}
.view-articles-panel .Health-Science .thumb:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e902";
  color: #777;
  color: #6ac5ca;
}
.view-articles-panel .Health-Science .thumb a:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\e902";
  color: #777;
  color: #bca0da;
}

.view-articles-panel .No,
.view-articles-topics-panel .No,
.embargoed{
    background-color:#fde1e1;
}
.embargoed{
    padding:10px;
    margin-bottom:0;
}

.date-info {
    padding: 10px;
    border: 1px solid #f3cbcb;
}


.view-disciplines .views-field.views-field-title a,
.view-articles-panel .views-field.views-field-title a,
.view-articles-topics-panel .views-field.views-field-title a,
.view-id-jobs_page.view-display-id-panel_pane_1 .job-title,
.view-id-jobs_page.view-display-id-panel_pane_2 .job-title{
    font-family: venticf-bold;
    font-size: 24px;
    line-height: 1.2;
    color: #333;
}

.view-disciplines .views-field.views-field-title a:hover,
.view-articles-panel .views-field.views-field-title a:hover,
.view-articles-topics-panel .views-field.views-field-title a:hover{
  color:#af8821;
}

.view-disciplines .views-field-views-conditional {
    margin-top: 11px;
}

.view-id-jobs_page.view-display-id-panel_pane_1 .job-title{
    font-size: 20px;
}

.view-id-jobs_page.view-display-id-panel_pane_1 .view-header {
    padding: 12px;
    float: right;
    max-width: 600px;
}

.view-id-jobs_page.view-display-id-panel_pane_1 .view-header p{
    font-size: 15px;
    line-height: 1.3;
}


.pane-factoids-panel-pane-1 {
    border: 2px solid #e4a824;
    padding: 0 20px;
    border-radius: 20px;
    background: #efeee5;
}


.view-articles-panel .views-field.views-field-title a:hover,
.view-articles-topics-panel .views-field.views-field-title a:hover,
a:hover .view-id-jobs_page.view-display-id-panel_pane_1 .job-title,
a .view-id-jobs_page.view-display-id-panel_pane_2 .job-title :hover {
    color: #f14747;
}

.jrecruiter-date {
    color: #b33e3e;
}

.view-id-jobs_page.view-display-id-panel_pane_1 .field-name-field-seo-description,
.view-id-jobs_page.view-display-id-panel_pane_2 .field-name-field-seo-description{
    margin-top:15px;
    color: #928080;
}

.view-articles-panel .thumb {
    width: 100px;
}



.view-articles-panel .views-exposed-form .views-exposed-widget .btn,
.view-profiles-land-page .views-exposed-form .views-exposed-widget .btn,
.view-all-events-past .views-exposed-form .views-exposed-widget .btn{
  margin-top: 0;
  padding: 10px 15px;
  margin-right: 0;
}

.views-exposed-widgets {
    margin-bottom: 0;
    background: #e0e0e0;
    padding: 0 12px 11px;
}


.view-articles-panel .panel-heading a.panel-title {
    padding: 10px 15px;
}

a.panel-title.fieldset-legend {
    padding: 10px 15px;
}

.shs-wrapper-processed select {
  font-family: 'open_sansregular', arial, sans-serif;
  display: inline-block !important;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 0 10px 10px 0;
  border: 1px solid #aaa;
}
.calendar-calendar .month-view .full td.single-day div.monthview,
.calendar-calendar .week-view .full td.single-day div.weekview,
.calendar-calendar .day-view .full td.single-day div.dayview,
.calendar-calendar .month-view .full td.multi-day div.monthview,
.calendar-calendar .week-view .full td.multi-day div.weekview,
.calendar-calendar .day-view .full td.multi-day div.dayview {
  background: #dedede !important;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.2;
  padding: 0;
  border-radius: 0;
}
.calendar-calendar .month-view .full td.single-day div.monthview .cutoff,
.calendar-calendar .week-view .full td.single-day div.weekview .cutoff,
.calendar-calendar .day-view .full td.single-day div.dayview .cutoff,
.calendar-calendar .month-view .full td.multi-day div.monthview .cutoff,
.calendar-calendar .week-view .full td.multi-day div.weekview .cutoff,
.calendar-calendar .day-view .full td.multi-day div.dayview .cutoff,
.calendar-calendar .month-view .full td.single-day div.monthview .continues,
.calendar-calendar .week-view .full td.single-day div.weekview .continues,
.calendar-calendar .day-view .full td.single-day div.dayview .continues,
.calendar-calendar .month-view .full td.multi-day div.monthview .continues,
.calendar-calendar .week-view .full td.multi-day div.weekview .continues,
.calendar-calendar .day-view .full td.multi-day div.dayview .continues {
  background: #dedede !important;
}

#events-sidebar h2 {
  margin-top: 24px;
  margin-bottom: 25px;
}
.views-exposed-form .views-exposed-widget .btn {
  margin-top: 33px;
}
ul.nav {
  line-height: 1.9;
}
.form-autocomplete .input-group-addon {
  background-color: #fff;
  width: 45px;
  margin-right: 10px;
}
#block-masquerade-masquerade .input-group .form-control {
  width: 230px;
}
.slick-prev:before,
.slick-next:before {
  font-size: 45px;
  line-height: 1;
  opacity: 1;
  text-shadow: 0px 0px 4px #000;
}
.slick-prev:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\edc6";
  color: #777;
  color: #fff;
}
.slick-next:before {
  font-family: 'icomoon';
  padding-right: 6px;
  content: "\edbe";
  color: #777;
  color: #fff;
}
.slick-next {
  right: 30px;
}
.slick-prev {
  left: 10px;
}

header#navbar {
display:none;
}
#mini-panel-header_block {
    background: black;
    padding-top: 15px;
}
.panel-pane.pane-page-logo {
    text-align: center;
}
.calendar-calendar td a {
  text-decoration: none;
  color: #666;
}
.calendar-calendar td a:hover {
  color: #000;
  text-decoration: none;
}
.today {
  border-color: #83ced6 !important;
}
.pane-system-user-menu li a,
.pane-menu-menu-login li a{
  font-family: 'open_sansregular', arial, sans-serif;
  display: inline-block !important;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  margin: 0 10px 10px 0;
  background: #337ab7;
  border: #2e6da4;
  color: #fff;
}
.pane-system-user-menu li a:hover,
.pane-menu-menu-login li a:hover{
  background: #286090;
  color: #fff;
}

#main-menu {
    text-align: center;
    margin-top: 30px;
}


#main-menu .sub-menu{
    text-align: left;
}

#main-menu .sub-menu a{
    border:none;
}

h2.pane-title,
h1.page-header{
    font-family: VentiCF-Medium;
    font-size: 45px;
    color: #888;
    margin-bottom: 20px;
}

h2.pane-title.ctools-collapsible-handle{
    font-family: VentiCF-Medium;
    font-size: 16px;
    color: #fff;
}
.page-node-edit h2.pane-title,
.page-node-add h2.pane-title{
    font-size: 25px;
}

.page-node-edit .ctools-collapsible-container h2.pane-title,
.page-node-add .ctools-collapsible-container h2.pane-title {
    font-size: 16px;
    color: #fff;
}

.responsive-menu {
  padding: 0;
}


@media (min-device-width: 740px) and (min-width: 740px), (max-device-width: 800px) and (min-width: 740px) and (orientation: landscape) {
  .responsive-menu li {
    display: inline-block;
  }
}
@media (max-width: 740px) {
  .menu-block-2{
      display:none;
  }
}
.menu-block-2 {
    text-align: center;
}
.responsive-menu li .sub-menu {
  padding: 0;
}
.responsive-menu li .sub-menu li {
  display: block;
}
.responsive-menu li .sub-menu li a {
  background: #cbab67;
}
.responsive-menu li .sub-menu li a:hover {
  background: #79b19f;
  color: white !important;
}
.responsive-menu li a,
.menu-block-2 li a{
  padding: 9px 23px;
  display: block;
  background: #79b19f;
  margin: 1px 0 0;
  color: white !important;
  border-radius: 4px;
}
.responsive-menu li a:hover,
.menu-block-2 li a:hover{
  background: #cbab67;
  color: white !important;
}
.responsive-menu li a.active,
.menu-block-2 li a.active{
  background: #cbab67;
}
.responsive-menu li a#admin {
  background: #aaa;
}

.responsive-menu li .sub-menu li a {
    background: transparent;
    color: #222222 !important;
}

.responsive-menu li a,
.responsive-menu li .sub-menu li a,
.menu-block-2 li a{
    padding: 0px 16px;
    display: block;
    background: transparent;
    margin: 1px 0 0;
    color: #222 !important;
    border-radius: 0;
    text-transform: uppercase;
    font-family: 'VentiCF-Bold';
    border-right: 3px solid #ccc;
    line-height: 1.5;
   font-size: 15px;
    letter-spacing: 0.1em;
}
.responsive-menu li a.active,
.responsive-menu li.active-trail a,
.responsive-menu li .sub-menu li a.active,
.menu-block-2 li a.active{
    color: #cbab67 !important;
    background: transparent;
}
.responsive-menu li a.active:hover,
.responsive-menu li .sub-menu li a.active:hover,
.menu-block-2 li a.active:hover{
    cursor:default;
}
.responsive-menu li a:hover,
.menu-block-2 li a:hover,
.responsive-menu li .sub-menu li a:hover{
    color: #cbab67 !important;
    background: transparent;
}
.responsive-menu li .sub-menu li a{
        background: #fff !important;
}
.responsive-menu li a#admin {
    background: transparent;
    color: #aaa !important;
}
.responsive-menu li a#home:before {
    display:none;
}

.responsive-menu li.last a,
.menu-block-2 li.last a{
    border:none;
}
.ui-tabs .ui-tabs-nav {
    font-size: 14px;
}
.nav>li>a:focus, .nav>li>a:hover {
    background-color: transparent;
}

#mini-panel-accomplishment{
    border-top:2px solid #aaa;
    margin-top:30px;
    padding-top:30px;
}

.page-node-61 p{
  font-size: 16px !important;
  font-family: 'Cabin';
  line-height: 1.65em;
  letter-spacing: 0.02em;
}

.page-node-60 p{
      font-size: 16px !important;
  font-family: 'Work Sans';
  line-height: 1.65em;
  letter-spacing: -.03em;
}

.page-node-54 p{
  font-size: 16px !important;
  font-family: 'open_sansregular';
  line-height: 1.65em;
}

.page-node-27 p{
  font-size: 16px !important;
  font-family: 'roboto_slabregular';
  line-height: 1.65em;
}

.page-node-52 p{
  font-family: 'VentiCF-Regular';
  line-height: 1.65em;
}



#search-block-form input.form-text{
    text-align: left;
    text-transform: none;
    font-family: venticf-regular;
    color: #111;
    border-radius: 0;
    border: 1px solid #fff;
}

#search-block-form input.form-text::placeholder{
    color:#111;
    text-align: center;
    text-transform: uppercase;
    font-family: venticf-bold;
    color: #111;
    border: 1px solid #fff;
}

#search-block-form button.btn.btn-primary{
    background-color: #111;
    border: 1px solid #fff;
    border-radius: 0;
    font-size: 21px;
    padding:6px;
}

.pane-system-user-menu {
    margin: 10px 0;
}
.pane-system-user-menu li.last,
.pane-menu-menu-login li.last{
    float: right;
}
.pane-system-user-menu li a,
.pane-menu-menu-login li a{
font-family: venticf-bold,arial,sans-serif;
    display: inline-block!important;
    padding: 0;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: none!important;
    border-radius: 0;
    margin: 0 10px 0px 0;
    background: 0 0;
    border: none;
    color: #fff;
    text-transform: uppercase;
}

.page-administration a.btn.btn {
    color: #fff;
}

.btn-group, .btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    margin-bottom: 6px;
}

.pane-menu-menu-footer{
    text-align:center;
}
.pane-menu-menu-footer a,
.footer .btn-primary,
.footer .btn:focus,
.footer .btn:focus{
    color: #111;
    font-family: venticf-bold !important;
    background-color: transparent;
    border-color: transparent;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    box-shadow:none;
    font-size: 14px;
}

.footer {
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-top: 0;
}

.region-footer {
    margin-top: 45px;
    padding-top: 35px;
    padding-bottom: 36px;
    border-top: 3px solid #E5E5E5;
}

#edit-extra-info {
    font-size: 14px;
}

.article-body{
    border-right: 2px solid #ddd;
    padding-right: 20px;
}

#reg-primary-institution,
#reg-id,
#reg-institution-id,
#reg-login-id,
#edit-xmlsitemap,
.hide,
#institution-edit .credentials,
#researcher-edit .credentials{
    display:none;
}

#edit-field-institution-registration,
#edit-field-title{
    display:none;
}
.page-user-signup-8 #edit-field-institution-registration,
.page-user-signup-8 #edit-field-title,
#institution-edit #edit-field-institution-registration,
#institution-edit #edit-field-title,
.page-node-add-profile #edit-field-title,
.page-node-edit #edit-field-title{
    display:initial;
}

.panelgroup {
    background: #ececec;
    padding: 15px;
    border: 1px solid #cccccc;
    margin-bottom: 20px;
    clear: both;
}

.page-node-edit .panelgroup h3,
.page-node-add .panelgroup h3 {
    border-bottom: 1px solid #e2cbcb;
    margin: -15px -15px 20px;
    padding: 10px 15px 10px;
    background: #e2e2e2;
    border-radius: 6px 6px 0 0;
}

a.tabledrag-handle .handle{
    height:28px;
}

.tabledrag-toggle-weight-wrapper {
    display: none;
}

#user-profile-form #edit-actions--2 {
    float: left;
    margin-top: 35px;

}

#magazine-title {
    transform: rotate(90deg);
    font-size: 160px;
    line-height: 63px;
    font-family: 'VentiCF-ExtraBold',arial,sans-serif;
    margin-left: 40px;
}

.view-id-magazine_articles.view-display-id-panel_pane_1 td span {
    border-right: 2px solid #aaa;
    margin-right: 6px;
    padding-right: 6px;
    display: inline-block;
    height: 30px;
}

.view-id-magazine_articles.view-display-id-panel_pane_1 td span:last-of-type {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
}

.view-id-magazine_articles.view-display-id-panel_pane_1 h3 a{
    font-family: 'VentiCF-Bold',arial,sans-serif;
    color: #333;
}

.view-id-magazine_articles.view-display-id-panel_pane_1 h3 a:hover{
    color: #e4a824;
}

.view-id-magazine_articles.view-display-id-panel_pane_1 h3 a:visited{
    color: #aaa;
}

.view-id-magazine_articles.view-display-id-panel_pane_1 h3{
    margin: 0 0 6px 0;
}

.view-id-magazine_articles.view-display-id-panel_pane_1 .field.field-name-field-image {
    width: 100px;
}

.view-magazine-slideshow .slick__slide {
    padding: 4px;
}

#read-every p {
    font-family: 'VentiCF-Bold',arial,sans-serif;
    color: #e4a824;
    font-size: 44px;
}

.pane-factoids-panel-pane-1 h2.pane-title{
    font-family: 'VentiCF-ExtraBold',arial,sans-serif;
    color: #e4a824;
    font-size: 33px;
}

#join-c2a{
    text-align:center;
    margin-bottom:30px;
}

#join-c2a img{
    width:100px;
}

#join-c2a p{
    font-family: 'VentiCF-Bold',arial,sans-serif;
    color: #e4a824;
    font-size: 27px;
        line-height: 30px;
    padding-top: 12px;
}

#yellow{
    background-color: #e4a824;
    color: #fff;
    position: relative;
    margin-left: 60px;
    padding: 30px 30px 30px 20px;
    line-height: 20px;
    border-radius: 0 20px 20px 0;
    margin-top:74px;
}

.case-study#yellow{
      margin-top:15px;
}

#yellow:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -55px;
    width: 45px;
    background: url(/sites/all/themes/researchsea/img/arrow-left2.png) no-repeat;
    -webkit-background-size: 45px 100%;
    -moz-background-size: 45px 100%;
    -o-background-size: 45px 100%;
    background-size: 45px 100%;
}

.view-id-testimonials.view-display-id-panel_pane_1 {
    border: 10px solid #a7e3f5;
    padding: 20px;
    position:relative;
    z-index:9;
}

#quotemark {
    text-align: center;
    margin-bottom: -30px;
    z-index: 10;
    position: relative;
}

#quotemark img{
    width:75px;
}

.icon-text {
    text-align: center;
    text-transform: uppercase;
    font-family: 'VentiCF-Bold',arial,sans-serif;
    color: #030405;
    margin-top: -30px;
}

.quote-author{
    color:#aaa;
    text-align:right;
    line-height:20px;
    margin-top:20px;
}

.quote{
    color:#222;
    font-family: 'VentiCF-Bold',arial,sans-serif;
    text-align:center;
    line-height:20px;
}
#explore-services{
    line-height:20px;
}


#explore-services h2{
    font-family:  'VentiCF-Extrabold',arial,sans-serif;
    font-size: 31px;
}
#explore-services h3{
font-family: 'VentiCF-Bold',arial,sans-serif;
    font-size: 22px;
    color: #333;
    letter-spacing: -0.03em;
}
#explore-services p{
    color: #e4a824;
    margin:0;
}
#explore-services .content-link,
#explore-services .distribution-link,
#explore-services .monitoring-link,
#explore-services .guidance-link{
    position: relative;
    margin-left: 70px;
    min-height: 60px;
    margin-bottom:20px;
}


#explore-services .content-link:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -70px;
    width: 70px;
    background: url(/sites/all/themes/researchsea/img/content_80px.png) no-repeat left center;
}

#explore-services.simple h3 {
    padding-top: 13px;
    color: #757474;
}

#explore-services.simple h2 {
    color: #333;
}

#explore-services .distribution-link:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -70px;
    width: 70px;
    background: url(/sites/all/themes/researchsea/img/distribution_80px.png) no-repeat left center;
}

#explore-services .monitoring-link:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -70px;
    width: 70px;
    background: url(/sites/all/themes/researchsea/img/monitoring_80px.png) no-repeat left center;
}
#explore-services .guidance-link:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -70px;
    width: 70px;
    background: url(/sites/all/themes/researchsea/img/guidance_80px.png) no-repeat left center;
}

#explore-services .content-link:hover *,
#explore-services .distribution-link:hover *,
#explore-services .monitoring-link:hover *,
#explore-services .guidance-link:hover *{
    color:#5bc0de;
    cursor:pointer;
}

#strapline{
    font-family:  'VentiCF-Extrabold',arial,sans-serif;
    text-align:center;
    color: #f52626;
    margin: 30px 0;
    font-size: 22px;
    letter-spacing: 0.05em;
}

#services-title{
    font-size: 80px;
    font-family:  'VentiCF-Extrabold',arial,sans-serif;
    color: #961717;
    text-align: center;
    margin-top: -105px;
}

#main-blurb{
    line-height: 20px;
    margin-left: 70px;
}

#existing{
    text-align: center;
    border-bottom: 2px solid #aaa;
    height: 20px;
    margin-bottom: 30px;
}

#existing .pane-content{
font-size: 22px;
    letter-spacing: 0.05em;
    font-family: 'VentiCF-Extrabold',arial,sans-serif;
    color: #aaa;
    text-transform: uppercase;
    display: inline-block;
    background: #fff;
    padding: 0 20px;
    margin-bottom: 20px;
}

#services-contact{
    border: 1px solid #aaa;
    padding: 10px;
    margin-top:30px;
}
#services-contact h2{
    margin: 0;
    color:#aaa;
    font-size:22px;
    text-transform:uppercase;
}



#services-contact p{
line-height: 20px;
}

.paragraphs-item-section-header .field-name-field-line{
    text-indent:-12000px;
    line-height: 20px;

}

.paragraphs-item-section-header .field-name-field-line:before{
    content:'';
    display:block;
    border-top:2px solid #aaa;
}
.paragraphs-item-section-header h2{
    margin: 0;
    color: #e4a824;
    font-size:22px;
    text-transform:uppercase;
    padding: 0 15px;
}

.entity-paragraphs-item.paragraphs-item-4-way h2{
    margin: 0;
    color: #e4a824;
    font-size:18px;
    text-transform:uppercase;
    margin-bottom:8px;
}

.paragraphs-item-section-header p{
    padding: 10px 15px;
    line-height: 20px;
}

.entity-paragraphs-item.paragraphs-item-trifold h2,
.entity-paragraphs-item.paragraphs-item-single-image-and-text h2{
    font-size: 22px;
    text-align: left;
    margin: 15px 0;
    color: #333;
}

.entity-paragraphs-item.paragraphs-item-trifold p,
.entity-paragraphs-item.paragraphs-item-single-image-and-text p,
.entity-paragraphs-item.paragraphs-item-4-way p{
        line-height: 20px;
}

.entity-paragraphs-item.paragraphs-item-4-way p{
        padding-bottom: 20px;
}

.entity-paragraphs-item.paragraphs-item-4-way h2.field-name-field-title{
    text-align: center;
    font-family: 'VentiCF-Extrabold',arial,sans-serif;
    text-transform: none;
    color: #848484;
    font-size: 40px;
    padding: 0 0 30px;
}

.entity.entity-paragraphs-item.paragraphs-item-video {
    max-width: 750px;
    display:block;
 }

 .entity.entity-paragraphs-item.paragraphs-item-image-wall{
    display:block;
 }

 .field-name-field-youtube {
    min-width: 330px;
}

#case-study-title{
    font-family: 'VentiCF-Extrabold',arial,sans-serif;
    color: #333;
    font-size: 36px;
    padding: 0 0 30px;
    text-transform:uppercase;
}

.group-trifold-1, .group-trifold-2, .group-trifold-3{
    margin-bottom:30px;
}

.form-group {
    margin-bottom: 15px;
/*    z-index: 12;*/
    position: relative;
}

input[type=checkbox], input[type=radio] {
    margin: 9px 0 0;
}

.page-administration #panel-bootstrap-column-2 {
    font-size: 14px;
}

.page-administration .ui-tabs .ui-tabs-nav li a {
    float: left;
    padding: 8px 6px 8px;
    text-decoration: none;
}

.page-administration .table>tbody>tr>td {
    padding: 3px;
}

.page-administration a {
    color: #4585bd;
}

.jobsdetails h2{
    margin: 0 0 30px;
    text-align: center;
    font-size: 25px;
}

.node-view .jobsdetails .pane-node-field-institution .pane-content:before{
display:none;
}

.jobstext .pane-node-field-requirements h3.field-label,
.jobstext .pane-node-field-responsibilities h3.field-label,
.jobstext .pane-node-field-how-to-apply h3.field-label
{
    border-top: 2px solid #cfa22b;
    padding-top: 22px;
    margin-top: 14px;
}

.view-article-contact-detail h3.field-label {
    margin-bottom: 6px;
}

p.field-name-field-seo-description{
    font-weight:700;
}

.field-name-field-websites a {
    display: block;
    margin-top: 6px;
}

.view-jobs-page p.field-name-field-seo-description{
    font-weight:400;
}

#edit-field-country,
.field-name-field-topics{
   /* z-index: 100;*/
}
.field-name-field-researcher-or-institution{
   z-index: 110;
}

div#ui-datepicker-div {
    z-index: 110 !important;
}



.pane-node-field-institution img,
.pane-node-field-recruiter-logo img,
.pane-node-field-other-logo img{
    mix-blend-mode: multiply;
}

hr {
    border-top: 1px solid #d4a11b;
}

.event-date {
    font-weight: 700;
    border: 2px solid #eae4e4;
    padding: 3px 10px;
    background: #f5f5f5;
    margin-bottom:10px;
}

div#admin-menu ul {
    font-size: 13px !important;
}

.smalltext p,
#utc-time {
    font-size: 12px !important;
}

.form-control.bef-select-as-checkboxes,
#edit-field-status-tid-1205.form-control{
    display: inline;
    width: inherit;
    height: inherit;
    padding: 1px;
    font-size: inherit;
    line-height: 1;
    color: inherit;
    background-color: #fff;
    background-image: none;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

.form-control {
    height: auto;
    padding: 10px 12px;
    font-size: 16px;
}
.field-widget-options-select {

}

#media-browser-page .form-item-replace-keep-original-filename,
#media-browser-page .field-name-field-file-image-title-text,
#media-browser-page .field-name-field-infobutton,
#media-browser-page .redirect-list,
#media-browser-page .metatags-form,
#media-browser-page .field-name-field-institution{
    display:none;
}

#media-browser-page .chosen-container {
    width: 80% !important;
}

.view-media-3 .views-exposed-form .views-exposed-widget .btn{
        margin-top: 0;

}

#edit-field-add-another-contact-person-und-0-actions {
    margin: 15px 0 0px 15px;
    float: left;
}

.page-node-add .button.browse{
    background: #5bc0de;
    color: #fff;
    margin-left: -10px;
    margin-top: 10px;
}

.pane-entity-field ul{
    line-height: 1.2;
    margin-bottom: 1.5em;
    margin-left: 0;
    padding-left: 22px;
    margin-top: .5em;
}

.help-block {
    clear: both;
}

.form-item-field-other-institution-und-0-title .help-block{
 display:none;
}

.page-node-add-profile h3{
    margin:0;
}

.page-node-add-profile .pane-node-field-role {
    margin-top: 52px;
}

.page-node-add-profile .pane-node-field-website {
    margin-top: -14px;
}

.standfirst{
    font-size:20px;
    font-weight:700;
    padding:0 0 20px;
}

.ui-accordion .ui-accordion-content {
    padding: 12px;
    border: 0;
    overflow: auto;
    background: #dedede;
    font-size:16px;
    line-height:1.3;
}

p.field.field-name-field-caption-image {
    font-size: 13px !important;
    padding: 10px;
    background: #eee;
    line-height: 17px;
    font-family: arial, helvetica, sans-serif;
}

.ui-timepicker-table td a {
    display: block;
    padding: 0.2em 0.3em 0.2em 0.5em;
    width: auto;
    cursor: pointer;
    text-align: right;
    text-decoration: none;
    font-size: 12px;
}

.ui-timepicker-table td a.ui-state-active{
    font-weight:700;
}

.panel-pane.pane-entity-field.pane-node-field-venue,
.panel-pane.pane-entity-field.pane-node-field-location{
    margin-bottom:0;
}

.page-node .region-content .panel-pane ul li {
    list-style: none
}




.page-node .region-content ul li p {
    display: inline-block;
}

.view-events-sidebar .views-exposed-form{
    margin-top:30px;
}

.view-events-sidebar .views-exposed-form .views-exposed-widget {
    float: none;
    padding: 10px 0;
}

.view-events-sidebar .views-exposed-form .views-exposed-widget.views-submit-button {
    float: left !important;
}

.form-control#edit-field-title-und-0-value{
    width:120px;
}

.view-events-sidebar .views-exposed-form .views-exposed-widget .btn {
    margin-top: 0;
}


#events-search{
    background-color:#ccc;
    padding: 12px;
    margin-top: 45px;
}

#events-search h2{
    background: #fff;
    padding: 10px;
    margin: 0;
    font-size: 1.5em;
}

#events-search .views-exposed-widgets{
      background-color:#fff;
}

#events-search .views-exposed-form .views-exposed-widget .btn {
    margin-top: 10px;
}


.page-administration .view-header,
.page-administration .view-footer{
    background: #e4e4e4;
    padding: 6px 20px;
    margin: 15px 0 0;
    border-radius: 17px 17px 0 0;
}

.nav-pills>li>a {
    position: relative;
    display: block;
    padding: 4px 8px;
    line-height: 1.5;
}

.nav-pills>li {
    font-size: 14px;
}


.nav-pills>li>a {
    border-radius: 9px 9px 0 0;
    background: #e8e8e8;
    letter-spacing: -1px;
}

.nav-pills>li>a:hover{
    background-color: #337ab7;
    color: #fff;
}

ul.addtocal_menu li a {
    color: inherit;
    text-decoration: none;
    display: inline;
    padding: 0px;
}

ul.addtocal_menu {
background: #f5f5f5;
    margin: 10px !important;
}

div#panel-bootstrap-region-center {

    position: relative;
}

#mailchimp {
    background: #eee;
    text-align: center;
    padding: 10px 0 20px;
    border: 1px solid #d2d2d2;
}

#mailchimp #edit-mergevars-email,
#mailchimp #edit-mergevars-country {
    width: 90%;
    margin: 0 auto 30px;
}

#mailchimp #mailchimp-newsletter-a29c895971,
#mailchimp #mailchimp-newsletter-51220e1fd7,
#mailchimp #mailchimp-newsletter-60073a6bc8{
    display: inline-block;
    margin: 0 10px 20px;
}

#mailchimp #mailchimp-newsletter-a29c895971-mergefields,
#mailchimp #edit-actions,
#mailchimp-footer .checkbox,
#mailchimp .checkbox{
    display: inline-block;
}
#mailchimp .checkbox,
#mailchimp-footer .checkbox{
    margin: 0 8px;
}


#mailchimp #edit-submit {
    font-size: 18px;
    line-height: 1.7;
    margin-top: 6px;
    background: #aaaaaa;
    color: #fff;
}

#mc_embed_signup {
    background: transparent !important;
}

#mailchimp-footer .mailchimp-newsletter-mergefields{
    display:inline-block;
    width:200px;
}

#mailchimp-footer .mailchimp-newsletter-mergefields .form-control{
    padding:5px 10px;
}
#mailchimp .form-type-checkboxes,
#mailchimp .form-type-checkboxes .checkbox .control-label,
#mailchimp-footer .form-type-checkboxes,
#mailchimp-footer .form-type-checkboxes .checkbox .control-label,
#edit-field-arn-newsletters-und-0 .form-type-checkboxes,
#edit-field-arn-newsletters-und-0 .form-type-checkboxes .checkbox .control-label,
#mailchimp-footer .form-actions{
        display:inline-block;
}


#mailchimp .form-type-checkboxes .control-label,
#mailchimp-footer .form-type-checkboxes .control-label,
#edit-field-arn-newsletters-und-0 .control-label{
        display:none;
}


#mailchimp-footer button {
    font-size: 14px;
    padding: 9px 10px;
    line-height: 1;
    background: #aaa;
    color: #fff;
    margin-top: 5px;
    margin-left: 6px;
}
#mailchimp-footer button:hover {
    background: white;
    color: #888;
}
#edit-field-arn-newsletters-und-0 .panel-title{
    font-weight: 700;
    font-size: 19px;
    color:#525f7b;
}

#share-footer,
#mailchimp-footer {
    text-align: center;
}

#share-footer .share-buttons{
    margin-top:-17px;
}

#mini-panel-clone_of_sharer {
    background: #dedede;
    border-bottom: 3px solid #cec9c9;
    margin-bottom: 30px;
}

a.more {
    display: inline-block;
    padding: 3px 17px 6px;
    background: #cfa22b;
    color: #fff;
    border: 1px solid #c7ab5e;
    border-radius: 6px;
    margin: 20px 0;
    font-size: 18px;
    line-height: 1.4;
}

a.more:after {
    font-family: 'icomoon';
    padding-left: 12px;
    content: "\edbb";
    color: #fff;
    font-size: 12px;
}

a:hover.more {
    background: #5b9096;
    color: #fdfdfd;
    border: 1px solid #608286;
}

a.more.articles:before{
    font-family: 'icomoon';
    padding-right: 12px;
    content: "\e90b";
    color: #000;
}

a.more.events:before{
    font-family: 'icomoon';
    padding-right: 12px;
    content: "\ea63";
    color: #000;
}

a.more.blogs:before{
    font-family: 'icomoon';
    padding-right: 12px;
    content: "\e920";
    color: #000;
}
.join-buttons {
    padding: 10px 30px 20px;
    border: 1px solid #f5d88c;
    margin: 40px 0;
    background: #efeadf;
}

.join-panel {
    background: #fff;
    padding: 0px 15px 12px;
    border: 1px solid #5b9096;
}


.join-panel a {
    display: block;
    background: #5b9096;
    padding: 6px 12px 9px;
    color: #fff;
    border: 1px solid #547f84;
    border-radius: 6px;
}

.join-panel a:hover {
    background: #cfa22b;
    color: #fff;
    border: 1px solid #cfa22b;
}

#sharelinks,
#follow{
    text-align: center;
}

#share-footer a,
#sharelinks a{
    display: inline-block;
    padding: 0;
    color: #aaa;
    border-radius: 6px;
    margin: 8px;
    font-size: 18px;
    visibility: hidden;
    width: 50px;
    overflow: hidden;
    height: 50px;
}

#follow a{
    display: inline-block;
    padding: 0;
    color: #aaa;
    border-radius: 6px;
    margin: 8px;
    font-size: 18px;
    visibility: hidden;
    width: 50px;
    overflow: hidden;
    height: 50px;
}

#share-footer a:hover:before,
#sharelinks a:hover:before,
#follow a:hover:before{
    color:#5b9096 !important;
}


#share-footer a:before,
#sharelinks a:before,
#follow a:before
{
    font-family: 'icomoon';
    padding-right:12px;
    visibility:visible;
    font-size: 35px;
    -webkit-transition: all 0.35s ease-out;
    -moz-transition: all 0.35s ease-out;
    -o-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out 0s;
}

#share-footer a.facebook,
#sharelinks a.facebook,
#follow a.facebook{
    background: #3e5895;
}

#share-footer a.facebook:before,
#sharelinks a.facebook:before,
#follow a.facebook:before{
    content:"\eef0 ";
    color: #3e5895;
}
#share-footer a.linkedin,
#sharelinks a.linkedin,
#follow a.linkedin{
    background: #197ab2;
}
#share-footer a.linkedin:before,
#sharelinks a.linkedin:before,
#follow a.linkedin:before{
    content:"\ef28";
    color: #197ab2;
}
#share-footer a.google,
#sharelinks a.google,
#follow a.google{
    background: #da4c3f;
}
#share-footer a.google:before,
#sharelinks a.google:before,
#follow a.google:before{
    content:"\eeeb";
    color: #da4c3f;
}
#share-footer a.twitter,
#sharelinks a.twitter,
#follow a.twitter{
    background: #5cabea;
    height: 54px;
}
#share-footer a.twitter:before,
#sharelinks a.twitter:before,
#follow a.twitter:before{
    content:"\e901";
    color: #5cabea;
    font-size: 41px;
}

.social {
    display: table;
    margin: 0 auto 30px;
    vertical-align: top;
    border: 4px solid #ccc;
    border-radius: 19px;
    background: #eaeaea;
}

.social-label {
    display: table-cell;
    vertical-align: middle;
    padding: 18px;
    font-weight: 700;
}

.social-links {
    display: table-cell;
    vertical-align: middle;
}


form .field-multiple-table {
    margin: 0;
    min-height: 110px;
}

.join-researcher:before{
    font-family: 'icomoon';
    padding-right: 12px;
    content: "\eb08";
    color: #000;
}

.join-institution:before{
    font-family: 'icomoon';
    padding-right: 12px;
    content: "\e999";
    color: #000;
}

.join-journalist:before{
    font-family: 'icomoon';
    padding-right: 12px;
    content: "\e90b";
    color: #000;
}


.future {
    background-color: #eaeaea;
    border-bottom: 2px solid #fff;
}

.past {
    background-color: #e0e0e0;
    border-bottom: 2px solid #d0cdcd;
}


.page-user .tabs--primary li:nth-child(1) {
display:none;
}

.page-user .tabs--primary li:nth-child(2) a:before {
    font-family: 'icomoon';
    padding-right: 12px;
    content: "\eb08";
    color: #aaa;
}

.page-user .tabs--primary li:nth-child(3) a:before {
    font-family: 'icomoon';
    padding-right: 12px;
    content: "\e999";
    color: #aaa;
}

.page-user .tabs--primary li:nth-child(4) a:before {
    font-family: 'icomoon';
    padding-right: 12px;
    content: "\e90b";
    color: #aaa;
}


.page-user .tabs--primary li:nth-child(5) a:before {
    font-family: 'icomoon';
    padding-right: 12px;
    content:"\e911";
    color: #aaa;
}

.page-user .tabs--primary li:nth-child(6) a:before {
    font-family: 'icomoon';
    padding-right: 12px;
    content:"\ebfa";
    color: #aaa;
}

.topics-page .panels-bootstrap-tabs .nav-tabs li a:before,
.disciplines-page .panels-bootstrap-tabs .nav-tabs li a:before{
    font-family: 'icomoon';
    padding-right: 12px;
    color: #aaa;
}
.topics-page .panels-bootstrap-tabs .nav-tabs li:nth-child(1) a:before,
.disciplines-page .panels-bootstrap-tabs .nav-tabs li:nth-child(1) a:before{
    content:"\e90b";
}
.topics-page .panels-bootstrap-tabs .nav-tabs li:nth-child(2) a:before,
.disciplines-page .panels-bootstrap-tabs .nav-tabs li:nth-child(2) a:before{
    content:"\ea63";
}
.topics-page .panels-bootstrap-tabs .nav-tabs li:nth-child(3) a:before,
.disciplines-page .panels-bootstrap-tabs .nav-tabs li:nth-child(3) a:before{
    content:"\eb08";
}

.node-type-institution .panels-bootstrap-tabs .nav-tabs li a:before{
    font-family: 'icomoon';
    padding-right: 12px;
    color: #aaa;
}

.node-type-institution .panels-bootstrap-tabs .nav-tabs li:nth-child(1) a:before{
    content:"\e90b";
}
.node-type-institution .panels-bootstrap-tabs .nav-tabs li:nth-child(2) a:before{
    content:"\ea63";
}
.node-type-institution .panels-bootstrap-tabs .nav-tabs li:nth-child(3) a:before{
    content:"\ea4d";
}
.node-type-institution .panels-bootstrap-tabs .nav-tabs li:nth-child(4) a:before{
    content:"\eb08";
}


.page-user .chosen-container#edit_field_country_und_chosen,
.page-user .chosen-container#edit_timezone__2_chosen{
    background: 0 0;
    padding: 0;
    width: 100%!important;
}

.page-user ul.tabs--primary.nav.nav-tabs {
    margin: 0;
}

.page-user .nav-tabs>li.active>a, .page-user .nav-tabs>li.active>a:focus, .page-user .nav-tabs>li.active>a:hover {
    background-color: #f3f3f3;
}


.page-user #block-system-main {
    border: 1px solid #ddd;
    border-top: none;
    padding: 60px;
    background: #f3f3f3;
}


.page-user #edit-timezone {
    padding: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.page-user #edit-timezone .panel-body {
    border-top-color: #ddd;
    padding: 0;
}


.page-user #edit-timezone .panel-heading{
    display:none;
}

.page-user-signup-10 #block-block-1,
.page-user-signup-9 #block-block-2,
.page-user-signup-8 #block-block-3{
    padding: 10px 60px 0;
    background: #f3f3f3;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
}

.form-item-pass.form-type-password-confirm {
    padding: 10px;
    border: 1px solid #d4d4d4;
    background: #e4e2e2;
}

.form-item-pass.form-type-password-confirm:before{
    content:"Set your password";
}


.page-user-signup .form-item-pass.form-type-password-confirm:before{
    content:"Set your password";
}


#mini-panel-header_block {
    background: #323232;
    padding-top: 15px;
}

.related-title {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.1;
    border-top: 1px solid #cccccc;
    padding-top: 9px;
    margin-bottom: 12px;
}

#mini-panel-mobile_logo_search_menu .user-menu{
  margin-left:20px;
}

.panel-pane {
    clear: both;
}


#mini-panel-institutions_associated_content .views-field.views-field-title {
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}


#mobile-search-container,
.responsive-menus{
  display: none;
}

.pane-menu-block-1{
    background: #fff;
    margin-bottom: 0;
    padding: 10px 0;
    text-align: center;
}

.panels-bootstrap-views_list {
    padding-bottom: 30px;
}

.panels-bootstrap-views_list .views-field-title{
    line-height: 1;
    padding-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
}

.panels-bootstrap-views_list .views-field.views-field-field-institutions {
    font-weight: 700;
    color: #aaa;
}

#panel-bootstrap-region-info {
    text-align: right;
}

.node-view .pane-node-field-image-1-copyright,
.node-view .pane-node-field-image-2-copyright,
.node-view .pane-node-field-image-3-copyright,
.node-view .field-name-field-copyright{
    background: #aaa;
    padding: 0 10px 8px;
    font-size: .7em;
    color: #482e2d;
    text-align: right;
    margin-top: -10px;
}
.node-view .pane-node-field-image-1-copyright .pane-content:before,
.node-view .pane-node-field-image-2-copyright .pane-content:before,
.node-view .pane-node-field-image-3-copyright .pane-content:before,
.node-view .field-name-field-copyright .field-item:before{
    font-family: 'icomoon';
    color: #222;
    content: "\e903";
}

img.file-icon {
    width: auto;
}

#edit-field-topics .checkbox {
    display: inline-block;
    margin-right: 20px;
}


.page-node-add.node-type-profile .image-preview, .page-node-edit.node-type-profile .image-preview {
    float: left;
    margin-right: 15px;
}





img.file-icon {
    width: auto !important;
}

.join-button a {
    display: inline-block;
    padding: 3px 17px 6px;
    background: #cfa22b;
    color: #fff;
    border: 1px solid #c7ab5e;
    border-radius: 6px;
    margin: 20px 0;
    font-size: 18px;
    float: right;
    margin-top: -70px;
    line-height: 1.4;
 }

.join-button a:hover {
    background: #ab892e;
    border: 1px solid #8a6d1d;
 }


.node-view .view-related-articles .view-header {
    border-bottom: 3px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.node-view .view-related-articles .view-footer {
    border-top: 3px solid #ccc;
    padding-top: 0px;
    margin-top: -6px;
}


.node-view .view-related-articles .view-footer a {
    display: inline-block;
    background: #c7ba54;
    padding: 0 6px 3px;
    color: #fff;
    margin: 8px 0 0 6px;
    border-radius: 6px;
    border: 1px solid #bbae49;
}

.node-view .view-related-articles .view-footer a:hover {
    background: #5496c7;
    color: #fff;
    border: 1px solid #4978bb;
}

.related {
    border-bottom: 1px solid #ccc;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.view-events-sidebar div {
    margin-bottom: 12px;
}

.node-view .panelgroup .field-item {
    margin-bottom: 0;
}

.focal-point-help {
    font-size: 12px;
    color: #777;
}

.media--loading::before {
    display: none;
}

.page-node .region-content .panel-pane p ul li {
    list-style: circle;
}

.indented {
    margin-left: 20px;
}

.form-required {
    color: red;
}

a#logo {
    display: block;
    margin-left: 10px;
}

.panelgroup .field-name-field-institution-reference h2 {
    font-size: 21px;
}

























#mobile-menu{
	display:none
}



@media (max-width: 767px) {


body {
    font-size: 15px;
}

#panel-bootstrap-column-main-column {
    z-index: 1;
}

#panel-bootstrap-region-image {
    margin-bottom: 10px;
}


a#logo {
    margin-left: 0px;
}


#mobile-menu{
	display:block;
}

#mobile-menu .menu-block-1 .collapsed a:after {
    content: "\edbe";
    font-family: icomoon;
    font-size: 75%;
    padding-left: 3px;
    font-weight: bold;
}

.responsive-menu {
    padding: 15px 0;
    background: #fff;
    margin-bottom: 0;
    text-align: center;
}

.responsive-menus.responsified{
  position: relative;
}

.responsive-menus.responsified span.toggler {
    padding: 1px;
    display: block;
    background: transparent;
    color: #fff;
    border-radius: 0;
    box-shadow: none;
    font-size: 2em;
    line-height: 1;
    text-align: center;
    cursor: pointer;
    outline: none;
    position: absolute;
    top: -41px;
    right: 2px;
    width: 50px;
    height: 40px;
}

.pane-menu-menu-login {
    margin-left: 15px;
}

.responsive-menus.responsified.responsive-toggled span.toggler {

}

.responsive-menus.responsified .responsive-menus-simple {
    background: #323232;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
}

.responsive-menus.responsified.responsive-toggled span.toggler {
    border-bottom: none;
}

.responsive-menus.responsified .responsive-menus-simple li a {
    padding: 0.7em 5%;
}

#mobile-search-container,
.responsive-menus{
  display: block;
}

#mobile-search-container .pane-content{
  position:relative;
}


#mobile-search {
    position: absolute;
    top: -40px;
    right: 50px;
}

#mobile-search a.mobile-search:before {
    font-family: icomoon;
    padding-right: 12px;
    visibility: visible;
    font-size: 27px;
    -webkit-transition: all .35s ease-out;
    -moz-transition: all .35s ease-out;
    -o-transition: all .35s ease-out;
    transition: all .35s ease-out 0s;
    content: "\eb30 ";
    color: #fff;
    background: transparent;
    height: 40px;
    width: 50px;
    display: block;
    text-align: center;
}

#mobile-search a {
    visibility: hidden;
    overflow: hidden;
}

a#logo img {
    margin-left: 15px;
}


.panel-pane.pane-views-panes.pane-carousels-panel-pane-2 {
    margin-right: 15px;
}

.pane-search-form,
.pane-menu-block-1{
  display:none;
}

.pane-system-user-menu li.last,
.pane-menu-menu-login li.last {
    float: none;
}

.pane-system-user-menu {
    margin: 20px 0px 10px 20px;
}


.join-buttons h2, h2.pane-title, h1.page-header,.pane-front-page-events-panel-pane-3 h2.pane-title, .entity-paragraphs-item.paragraphs-item-4-way h2.field-name-field-title, #explore-services h2{
    font-size: 30px;
    font-family: VentiCF-Medium;
    color: #888;
}


.front .pane-recent-articles {
    margin-top: -30px;
}

.slick__arrow {
    top: 20%;
}


.view-id-carousels.view-display-id-panel_pane_1{
    padding-top:15px;
}
.carousel-text {
    position: relative;
    top: 0;
    padding:10px;
    -ms-transform: translateY(0);
    transform: translateY(0);

}
.carousel-text p {
    font-size: 23px!important;
    color: #484848;
    text-shadow: none;
}

.view-front-page-events .views-field-title, .view-featured-accomplishment .views-field-title, .view-admin-events.view-display-id-panel_pane_2 .views-field-title, .view-profiles-land-page.view-display-id-panel_pane_2 .views-field-nothing .name, .view-profiles-land-page.view-display-id-panel_pane_4 .views-field-nothing .name, .view-profiles-land-page.view-display-id-panel_pane_3 .views-field-nothing .views-field-title, .view-events-sidebar.view-display-id-panel_pane_1 .views-field-title, .view-events-land-page .views-field-title, .view-institutions-landing-page .views-field-nothing .name, .view-institutions-landing-page.view-display-id-panel_pane_2 .views-field-title,.pane-front-page-events-panel-pane-3 h2.pane-title, .view-front-page-events.view-display-id-panel_pane_3 .views-field-title a, .panels-bootstrap-views_list .views-field-title {
    font-size: 20px;
}

.entity-paragraphs-item.paragraphs-item-2-column-text .image-left, .entity-paragraphs-item.paragraphs-item-text-and-left-image .image-left, .entity-paragraphs-item.paragraphs-item-text-and-right-image .image-left {
    float: none;
    padding: 15px 0;
    width:100%;
}

.entity-paragraphs-item {
    margin-bottom: 20px;
    clear: both;
    display: flex;
}

.paragraphs-item-small-left-image-text {
    margin-left: -15px;
    margin-right: -15px;
}

.view-front-page-events.view-display-id-panel_pane_2 .views-field-nothing {
    padding-top: 30px;
}

#mailchimp {
    background: transparent;
    text-align: left;
    padding: 10px 0 20px;
    border: none;
}

#mailchimp .checkbox, #mailchimp-footer .checkbox {
    margin: 14px 8px 0px;
}

#mailchimp input[type=checkbox], #mailchimp input[type=radio] {
    margin: 5px -15px 0;
}



#mailchimp #edit-mailchimp-lists-interest-groups-53aa20bcc8 {
    margin: -15px -10px 10px;
}

#mailchimp-footer .mailchimp-newsletter-mergefields .form-control {
    padding: 10px 15px;
}

#mailchimp-footer .mailchimp-newsletter-mergefields {
    display: inline-block;
    width: 95%;
    margin-bottom: 15px;
}

#mini-panel-clone_of_sharer {
    background: transparent;
    border-bottom: none;
    margin-bottom: 30px;
}

a.more,
.join-panel a,
a.more.articles{
    width: 100%;
    text-align: center;
    padding: 6px 17px 9px;
    color: #fff;
}

#mini-panel-accomplishment {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
}

.panel-pane.pane-views-panes.pane-admin-events-panel-pane-2 {
    padding-top: 20px;
}

.join-buttons {
    padding: 0;
    border: none;
    background: transparent;
}

.articles-info {
    display: table;
    margin-bottom: 10px;
}

.articles-inst-date {
    display: table-cell;
    vertical-align: top;
    padding-left: 15px;
}

.articles-image {
    display: table-cell;
    width: 100px;
}

.articles-description {
    padding-bottom: 10px;
}

a.articles {
    color: #333;
    display: block;
    margin-bottom: 30px;
}

.articles .views-field.views-field-title {
    font-weight: 700;
    font-size: 19px;
    padding-bottom: 10px;
    line-height: 1.2;
}
.articles-institution {
    display: block;
    font-family: VentiCF-Bold;
    color: #888;
}

.standfirst {
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 20px;
    margin-bottom: 0;
    line-height: 1.3;
}

.views-exposed-widgets {
    margin-bottom: 0;
    background: transparent;
    padding: 15px 10px;
}


p, ol, ul {
    font-size: 15px;
    line-height: 1.6;
    overflow-wrap: break-word;
}

input[type=checkbox], input[type=radio] {
    margin: 5px 0 0;
}

.not-logged-in.page-user .nav-tabs {
    border-bottom: none;
    margin-bottom: 20px !important;
}

.not-logged-in.page-user .nav-tabs>li.active>a, .not-logged-in.page-user .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: #ddd;
}

.not-logged-in.page-user .nav-tabs>li>a {
    margin: 0 5px 10px 0;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px;
}

.not-logged-in.page-user #block-system-main {
    border-top: none;
    border: none;
    padding: 0;
    background: transparent;
}

.page-user-signup-10 #block-block-1, .page-user-signup-9 #block-block-2, .page-user-signup-8 #block-block-3 {
    padding: 0 0 20px;
    background: transparent;
    border-left: none;
    border-right: none;
}

.not-logged-in.page-user .field-name-field-institution-reference{
    display:none;
}

#magazine-title {
    transform: none;
    font-size: 58px;
    line-height: 45px;
    font-family: venticf-extrabold,arial,sans-serif;
    margin-left: 0;
}

#magazine-info h2 {
    font-size: 20px;
}

#read-every p {
    font-size: 30px;
    line-height: 1;
}

#existing {
    text-align: center;
    border-bottom: none;
    height: auto;
    margin-bottom: 0;
}

.icon-text {
    margin-top: -17px;
}

#services-title {
    font-size: 40px;
    margin-top: -80px;
}

.view-profiles-land-page.view-display-id-panel_pane_2 .views-field-nothing, .view-events-land-page.view-display-id-panel_pane_1 .views-field-nothing, .view-institutions-landing-page.view-display-id-panel_pane_4 .views-field-nothing {
    display: block;
    height: auto;
}

.view-profiles-land-page.view-display-id-panel_pane_2 .views-field-nothing .description, .view-events-land-page.view-display-id-panel_pane_1 .views-field-nothing .date-display-range, .view-institutions-landing-page.view-display-id-panel_pane_4 .views-field-nothing .description {
    font-size: 15px;
    border-bottom: none;
    padding-bottom: 0;
}

.view-profiles-land-page.view-display-id-panel_pane_2 .views-field-nothing .name, .view-events-land-page.view-display-id-panel_pane_1 .views-field-nothing .views-field-title, .view-institutions-landing-page.view-display-id-panel_pane_4 .views-field-nothing .name,
.view-profiles-land-page .views-field-nothing .views-field-title {
    border-top: none;
    padding-top: 10px;
}

.view-profiles-land-page.view-display-id-panel_pane_2 .views-field-nothing .description, .view-events-land-page.view-display-id-panel_pane_1 .views-field-nothing .date-display-range, .view-institutions-landing-page.view-display-id-panel_pane_4 .views-field-nothing .description {
    font-size: 15px;
    border-bottom: none;
    padding-bottom: 0;
}

.view-institutions-landing-page .views-field-nothing,
.view-profiles-land-page .views-field-nothing{
  padding-bottom: 20px;
  margin-bottom: 30px;
  border-bottom: 2px solid #ccc;
}


#mailchimp #edit-mergevars-email,
#mailchimp #edit-mergevars-country {
    width: 100%;
}


.node-type-institution .panels-bootstrap-tabs .nav-tabs li a:before {
    display:none;
}

.join-button a {
float:none;
}

.views-exposed-widget.views-submit-button {
    clear: left;
}

}